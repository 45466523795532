import React from "react";
import {Col} from "react-flexbox-grid";
import {
  DocsContent,
  DocsTypesTitle,
  DocsTypesTitleBg,
} from "../../Style/DocumentsPage/DocumentsPage";
import {SetDocs} from "../../components/Documents/SetDocs";
//import {InsertTo} from "../../components/Documents/InsertTo";
import docsStrat from "../../assets/file/Prilozheniye_4_k_Polozheniyu_Struktura_opisaniya.pdf";
import docsMain from "../../assets/file/Polozheniye_25.04.pdf"
import sameMain from "../../assets/file/Prilozheniye_1_k_Polozheniyu_Terminy_i_opredeleniya.pdf"
import ibList from "../../assets/file/Prilozheniye_6_k_Polozheniyu_Forma_Zhyuri.docx"
//import logoDesign from "../../assets/file/logoDesign.7z"
import sameList from "../../assets/file/Prilozheniye_2_k_Polozheniyu_Forma_Regional_nyy_koordinator.docx"
import sameList1 from "../../assets/file/08-619_ot_21.03.2023.pdf"
import sameList2 from "../../assets/file/Spisok_regionalnykh_koordinatorov_29.05.pdf"
import sameList3 from "../../assets/file/Grafik_provedeniya_regionalnykh_etapov_02.05.docx"
import sameList4 from "../../assets/file/Spisok_pobediteley.pdf"
import sameList5 from "../../assets/file/ВККР_методические_разработки_воспитательных_мероприятий_А5_02.pdf"
// import {WinnerDocs} from "../../components/Documents/WinnersDocs";

export const DocsTypes = () => {


  return (
    <>
      <Col lg={10} md={12}>
        <DocsContent>
        <SetDocs
            file={sameList5}
            type={'PDF'}
            size={'15,6 Мб'}
            title={'Электронный сборник методических разработок воспитательных мероприятий, победителей, призеров и лауреатов Конкурса'}
          />
        <SetDocs
            file={sameList4}
            type={'PDF'}
            size={'634 Кб'}
            title={'Список победителей'}
          />
        <SetDocs
            file={sameList3}
            type={'DOCX'}
            size={'35 Кб'}
            title={'График проведения региональных этапов'}
          />
        <SetDocs
            file={sameList2}
            type={'PDF'}
            size={'640 Кб'}
            title={'Список региональных координаторов'}
          />
        <SetDocs
            file={sameList1}
            type={'PDF'}
            size={'193 Кб'}
            title={'Письмо №08-619 от 21.03.2023 «О проведении Всероссийского конкурса на лучшие методические разработки»'}
          />
          <SetDocs
            file={docsMain}
            type={'PDF'}
            size={'25,4 Мб'}
            title={'Положение о IV Всероссийском конкурсе на лучшие методические разработки воспитательных мероприятий'}
          />
          <SetDocs
            file={sameMain}
            type={'PDF'}
            size={'60 Кб'}
            title={'Приложение 1 к Положению «Термины и определения»'}
          />
          <SetDocs
            file={sameList}
            type={'DOCX'}
            size={'13 Кб'}
            title={'Приложение 2 к Положению «Форма представления информации о региональном координаторе»'}
          />
          <SetDocs
            file={docsStrat}
            type={'PDF'}
            size={'370 Кб'}
            title={'Приложение 4 к Положению «Структура описания методической разработки»'}
          />
          <SetDocs
            file={ibList}
            type={'DOCX'}
            size={'10 Кб'}
            title={'Приложение 6 к Положению «Форма представления информации о кандидатуре в состав жюри»'}
          />
        </DocsContent>
      </Col>

      {/*<Col lg={12}>*/}
      {/*  <DocsTypesTitle>*/}
      {/*    <DocsTypesTitleBg/>*/}
      {/*    <h2>Дипломы конкурса</h2>*/}
      {/*  </DocsTypesTitle>*/}
      {/*</Col>*/}

      {/*<Row lg={6} sm={6}>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

    </>
  )
}
