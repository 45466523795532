import React from "react";
import {AdminHeader} from "../../components/AdminPanel/AdminHeader";
import {AdminPanelComponent, AdminPanelContainer, AdminPanelContent} from "../../Style/Admin/AdminPanel";
import {AdminExpertSidebar} from "../../components/AdminPanel/AdminExpertSidebar";
import {Redirect, Route} from "react-router-dom";
import {ExpertInfoCard} from "../AdminExpertSteps/ExpertInfoCard";
import {ExpertWorkCheck} from "../AdminExpertSteps/ExpertCheckWork";
import {ExpertCheckId} from "../AdminExpertSteps/ExpertCheckId";

export const AdminExpert = props => {

  return(
    <AdminPanelContainer>
      <AdminHeader/>
      <AdminPanelContent>
        <AdminExpertSidebar/>
        <AdminPanelComponent>
          <Route path={`${props.match.url}/info`} exact component={ExpertInfoCard}/>
          <Route path={`${props.match.url}/check`} exact component={ExpertWorkCheck}/>
          <Route path={`${props.match.url}/check/:id`} component={ExpertCheckId}/>
          <Redirect to={`${props.match.url}/info`}/>
        </AdminPanelComponent>
      </AdminPanelContent>
    </AdminPanelContainer>
  )
}