import React, {useEffect} from "react";
import {NewsPageContainer, NewsPageContent} from "../Style/NewsPage/NewsPage";
import {BreadcrumbsItem, BreadcrumbsItemLink, DocumentsPageContent} from "../Style/DocumentsPage/DocumentsPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {DisplayFlex, PageTitle} from "../Style/Pages";
import { TableStyle} from "../Style/TableStyles";
import {usePagination, useTable} from "react-table";

const columns = [
  {
    Header: '№',
    accessor: 'number'
  },
  {
    Header: 'Фамилия, имя, отчество участника, место работы, должность',
    accessor: 'name'
  },
  {
    Header: 'Субъект Российской Федерации',
    accessor: 'region'
  },
  {
    Header: 'Тематическое направление работы',
    accessor: 'thematic'
  },
  {
    Header: 'Статус',
    accessor: 'status'
  },
]

const setName = (data) => (
  <>
    <p>{data.name}</p>
    <p>{data.work}</p>
  </>
)

const data = [
  {
    number: '1',
    name: setName({name: 'Держурина Елена Николаевна,', work: 'учитель иностранного языка ГБОУ «Школа №1529 имени А.С. Грибоедова»'}),
    region: 'Москва – г.ф.з.',
    thematic: 'Патриотическое\n' +
      'воспитание\n' +
      'и формирование\n' +
      'российской\n' +
      'идентичности',
    status: 'победитель',
  },
  {
    number: '2',
    name: setName({name: 'Зверько Татьяна Александровна,', work: 'учитель биологии МБОУ «Средняя общеобразовательная школа №26 с. Краснокумского Георгиевского района»'}),
    region: 'Ставропольский край',
    thematic: 'Экологическое\n' +
      'воспитание',
    status: 'призер',
  },
  {
    number: '3',
    name: setName({name: 'Субботина Наталья Борисовна,', work: 'учитель начальных классов МБОУ основная общеобразовательная школа №11 имени Героя Советского Союза Николая Кирилловича Пархоменко хутора Маевского муниципального образования Славянский район'}),
    region: 'Краснодарский\n' +
      'край',
    thematic: 'Приобщение\n' +
      'к культурному\n' +
      'наследию',
    status: 'призер',
  },
  {
    number: '4',
    name: setName({name: 'Кобзарь Александра Германовна,', work: 'учитель начальных классов ГБОУ Школа №1466 имени Надежды Рушевой'}),
    region: 'Москва – г.ф.з.',
    thematic: 'Патриотическое\n' +
      'воспитание и\n' +
      'формирование\n' +
      'российской\n' +
      'идентичности',
    status: 'лауреат',
  },
  {
    number: '5',
    name: setName({name: 'Родионова Татьяна Михайловна,', work: 'учитель английского языка ГБОУ средняя общеобразовательная школа №98 с углубленным изучением английского языка Калининского района Санкт-Петербурга'}),
    region: 'Санкт-\n' +
      'Петербург –\n' +
      'г.ф.з.',
    thematic: 'Трудовое\n' +
      'воспитание\n' +
      'и\n' +
      'профессионально\n' +
      'е\n' +
      'самоопределение',
    status: 'лауреат',
  },
  {
    number: '6',
    name: setName({name: 'Середа Ольга Владимировна,', work: 'учитель начальных классов ЧОУ Школа "Таврида"'}),
    region: 'Севастополь –\n' +
      'г.ф.з.',
    thematic: 'Патриотическое\n' +
      'воспитание\n' +
      'и формирование\n' +
      'российской\n' +
      'идентичности',
    status: 'лауреат',
  },
  {
    number: '7',
    name: setName({name: 'Билаш Юлия Васильевна,', work: 'учитель русского языка и литературы АНОО "Областная гимназия имени Е.М. Примакова"'}),
    region: 'Московская\n' +
      'область',
    thematic: 'Экологическое\n' +
      'воспитание',
    status: 'лауреат',
  },
  {
    number: '8',
    name: setName({name: 'Сосновских Светлана Владимировна,', work: 'учитель истории и обществознания ГБОУ гимназия №24 имени И.А. Крылова Санкт-Петербурга'}),
    region: 'Санкт-Петербург – г.ф.з.',
    thematic: 'Патриотическое воспитание \n' +
      'и формирование российской идентичности',
    status: 'лауреат',
  },
  {
    number: '9',
    name: setName({name: 'Пархоменко Ольга Владиславовна,', work: 'учитель МАОУ средняя общеобразовательная школа №16 имени В.П. Неймышева г. Тобольска'}),
    region: 'Тюменская область',
    thematic: 'Трудовое воспитание \n' +
      'и профессиональное самоопределение',
    status: 'лауреат',
  },
  {
    number: '10',
    name: setName({name: 'Храмова Вера Алексеевна,', work: 'учитель начальных классов ГБОУ «Лицей №1», г. Муром'}),
    region: 'Владимирская область',
    thematic: 'Экологическое воспитание',
    status: 'лауреат',
  },
  {
    number: '11',
    name: setName({name: 'Телегина Светлана Анатольевна,', work: 'учитель начальных классов МБОУ средняя общеобразовательная школа №47, г. Липецк'}),
    region: 'Липецкая область',
    thematic: 'Духовное и нравственное воспитание',
    status: 'лауреат',
  },
  {
    number: '12',
    name: setName({name: 'Кудряшова Елена Владимировна,', work: 'учитель МАОУ «Средняя общеобразовательная школа №14», г. Череповец'}),
    region: 'Вологодская область',
    thematic: 'Патриотическое воспитание \n' +
      'и формирование российской идентичности',
    status: 'лауреат',
  },
  {
    number: '13',
    name: setName({name: 'Азеева Ксения Владимировна,', work: 'учитель начальных классов МБОУ – гимназия №19 имени Героя Советского Союза В.И. Меркулова города Орла'}),
    region: 'Орловская область',
    thematic: 'Духовное и нравственное воспитание',
    status: 'лауреат',
  },
  {
    number: '14',
    name: setName({name: 'Родькина Раиса Николаевна,', work: 'учитель французского языка ГБОУ города Москвы «Школа №830»'}),
    region: 'Москва – г.ф.з',
    thematic: 'Духовное и нравственное воспитание',
    status: 'лауреат',
  },
]

export  const ResultPage = () => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 14}
    },
    usePagination
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <NewsPageContainer>
      <DocumentsPageContent>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={' / '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>Результаты конкурса</BreadcrumbsItemLink>,
                  pathname: '/result'
                }}/>
              </Breadcrumbs>
            </Col>
            <Row>
              <Col lg={12}>
                <DisplayFlex>
                  <PageTitle>Результаты конкурса</PageTitle>
                </DisplayFlex>
              </Col>
            </Row>
          </Row>

          <NewsPageContent>
            <TableStyle>
              <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </TableStyle>

          </NewsPageContent>
        </Grid>
      </DocumentsPageContent>
    </NewsPageContainer>
  )
}