import styled from 'styled-components'
import {DisplayFlex, PageTitle} from "../Pages";

export const OperatorPageContainer = styled.div`
  padding-bottom: 40px;
  font-family: "Roboto Condensed", sans-serif;
`;



export const BorderContainerLower = styled.div`
  padding-left: 20px;
  border-left: 2px dashed #f6a117;
`;

export const StageContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const AbsoluteDivStage = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: -20px;
  left: -30.5px;
  background-color: ${props => props.color};
  z-index: 1;
`;

export const AbsoluteDiv = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: -30.5px;
  background-color: ${props => props.color};
`;

export const StageTitle = styled.span`
  position: relative;
  display: block;
  font-size: 30px;
  letter-spacing: 1px;
  color: #1cb4d6;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 5;
`;

export const Dates = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 26px;
  color: #074b73;
  font-weight: 700;
  text-transform: uppercase;
`;

export const CalendarDatesContainer = styled.div`
  position: relative;
  margin-top: 40px;
  padding-left: 24px;
`;

export const Description = styled.span`
  font-size: 18px;
  line-height: 20px;
  color: #616875;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
`;


export const CalendarPageContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  
  & ${PageTitle} {
    margin-bottom: 0;
  }
  
  & ${DisplayFlex} {
    margin-left: 6px;
  }
`