import React from "react";
import {
  AboutUserItem,
  AboutUserText,
  AdminPanelViewTitle,
  ModeratorContainer,
  ModeratorContent, UploadButton
} from "../../Style/Admin/AdminPanel";
import {useHistory, useParams} from "react-router-dom";
import {TableBox} from "../../Style/UI";
import Select from "react-select";
import {toast, ToastContainer} from "react-toastify";
import {useHttp} from "../../hooks/http.hooks";
import {useSelector} from "react-redux";

const options = [
  {value: 0, label: '0'},
  {value: 1, label: '1'},
  {value: 2, label: '2'},
]

const optionsOne = [
  {value: 0, label: '0'},
  {value: 1, label: '1'},
]

export const ExpertCheckId = () => {
  const {id} = useParams()
  const {token} = useSelector(state => state.auth)
  const workCode = id.slice(-3).toUpperCase()
  const {request} = useHttp()
  const history = useHistory()

  const submitHandler = async e => {
    e.preventDefault()
    const data = new FormData(e.target)
    const result = {
      resultScore: '',
      score: {
        first: '',
        second: '',
        third: '',
        fourth: '',
        fifth: '',
        sixth: '',
        seventh: '',
        eighth: '',
        ninth: ''
      },
    }
    let resultScore = 0

    for (let [key, value] of data.entries()) {
      if (value === '') {
        return toast.error("Заполните все поля", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
      if (key[0] === '1') {
        result.score = {...result.score, first: +value + +result.score.first}
      }
      if (key[0] === '2') {
        result.score = {...result.score, second: +value + +result.score.second}
      }
      if (key[0] === '3') {
        result.score = {...result.score, third: +value + +result.score.third}
      }
      if (key[0] === '4') {
        result.score = {...result.score, fourth: +value + +result.score.fourth}
      }
      if (key[0] === '5') {
        result.score = {...result.score, fifth: +value + +result.score.fifth}
      }
      if (key[0] === '6') {
        result.score = {...result.score, sixth: +value + +result.score.sixth}
      }
      if (key[0] === '7') {
        result.score = {...result.score, seventh: +value + +result.score.seventh}
      }
      if (key[0] === '8') {
        result.score = {...result.score, eighth: +value + +result.score.eighth}
      }
      if (key[0] === '9') {
        result.score = {...result.score, ninth: +value + +result.score.ninth}
      }

    }
    for (const key in result.score) {
      resultScore += result.score[key]
    }
    result.resultScore = resultScore

    try {
      const data = await request('/api/expert/uploadCheckWork', 'POST', {result, userId: id}, {
        Authorization: `Bearer ${token}`
      })
      toast.info(data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
      setTimeout(() => {
        history.push('/admin/check')
      }, 2500)
    } catch (e) {
      toast.error("Что то пошло не так", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }

  }

  return (
    <ModeratorContainer>
      <ToastContainer/>
      <ModeratorContent>
        <AdminPanelViewTitle>Проверка работы</AdminPanelViewTitle>
        <AboutUserItem>
          <AboutUserText>Код работы: {workCode}</AboutUserText>
        </AboutUserItem>
        <form onSubmit={e => submitHandler(e)}>
          <TableBox>
            <thead>
            <tr>
              <th>
                Критерий
              </th>
              <th>
                Показатель
              </th>
              <th>
                <p>0 баллов –
                «показатель не проявлен»,</p>

                <p>1 балл –
                «показатель проявлен
                частично»,</p>
                <p>2 балла –
                «показатель проявлен в
                полной мере»</p>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td>1. Актуальность</td>
                <td>
                  1.1.Тема и содержание
                  воспитательного мероприятия
                  соответствуют направлениям
                  воспитательной работы,
                  определенным в Стратегии развития
                  воспитания в Российской Федерации
                  на период до 2025 г.
                </td>
                <td>
                  <Select
                    name={'11'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  1.2. Тема и содержание
                  воспитательного мероприятия
                  затрагивают проблемы, актуальные в
                  настоящий момент для российского
                  общества
                </td>
                <td>
                  <Select
                    name={'12'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>

              </tr>
              <tr>
                <td/>
                <td>
                  1.3. Содержание воспитательного
                  мероприятия актуализируют
                  социальный и личностный опыт
                  обучающихся
                </td>
                <td>
                  <Select
                    name={'13'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>

              </tr>
              <tr>
                <td>2. Ценностные основы и целевые
                  установки</td>
                <td>
                  2.1. Воспитательное мероприятие расширяет общие представления и
                  углубляет знания обучающихся о
                  базовых национальных ценностях
                </td>
                <td>
                  <Select
                    name={'21'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  2.2. Воспитательное мероприятие
                  способствует приобретению
                  обучающимися опыта социальной
                  деятельности с опорой на
                  конкретные базовые национальные
                  ценности
                </td>
                <td>
                  <Select
                    name={'22'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  2.3. Цель, задачи и планируемые
                  результаты воспитательного
                  мероприятия конкретны и
                  достижимы
                </td>
                <td>
                  <Select
                    name={'23'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td>3. Адресность</td>
                <td>
                  3.1. Цель, задачи и планируемые
                  результаты воспитательного
                  мероприятия соответствуют возрасту
                  обучающихся
                </td>
                <td>
                  <Select
                    name={'31'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  3.2. Содержание, форма
                  воспитательного мероприятия,
                  методы и приемы соответствуют
                  возрасту и интересам обучающихся
                </td>
                <td>
                  <Select
                    name={'32'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  3.3. В методической разработке
                  реализованы воспитательные
                  возможности различных видов
                  деятельности обучающихся
                  (познавательной, игровой, трудовой,
                  спортивной, художественной и др.)
                </td>
                <td>
                  <Select
                    name={'33'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  3.4. Воспитательное мероприятие
                  предоставляет возможности для
                  проявления и развития
                  индивидуальных и творческих
                  способностей обучающихся
                </td>
                <td>
                  <Select
                    name={'34'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td>4. Инновационность</td>
                <td>
                  4.1. Авторский замысел
                  воспитательного мероприятия
                  отличается оригинальным подходом
                  к раскрытию темы
                </td>
                <td>
                  <Select
                    name={'41'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  4.2. При реализации воспитательного
                  мероприятия используются
                  разнообразные воспитательные
                  технологии, методические приемы,
                  формы организации деятельности
                  обучающихся
                </td>
                <td>
                  <Select
                    name={'42'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  4.3. При реализации воспитательного
                  мероприятия используется
                  специально разработанное
                  дидактическое обеспечение
                </td>
                <td>
                  <Select
                    name={'43'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td>5. Целостность</td>
                <td>
                  5.1. Задачи и планируемые
                  результаты воспитательного
                  мероприятия соответствуют
                  заявленной цели
                </td>
                <td>
                  <Select
                    name={'51'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  5.2. Содержание и форма
                  воспитательного мероприятия,
                  педагогические технологии и методы
                  соответствуют целям, задачам и
                  планируемым результатам
                </td>
                <td>
                  <Select
                    name={'52'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  5.3. В методической разработке
                  представлены все структурные
                  элементы (в соответствии с
                  Приложением 1)
                </td>
                <td>
                  <Select
                    name={'53'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td>6. Ресурсная
                  обеспеченность</td>
                <td>
                  6.1. Для подготовки и проведения
                  воспитательного мероприятия
                  используются современные
                  информационные ресурсы
                </td>
                <td>
                  <Select
                    name={'61'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  6.2. Для достижения целей
                  воспитательного мероприятия
                  используются ресурсы внешней
                  образовательной и культурной среды
                  (учреждения науки, культуры,
                  детских общественных движений
                  волонтерской, военно-
                  патриотической и иной
                  направленности)
                </td>
                <td>
                  <Select
                    name={'62'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  6.3. К подготовке и проведению
                  воспитательного мероприятия
                  привлекаются родители (законные
                  представители) и члены семей
                  обучающихся
                </td>
                <td>
                  <Select
                    name={'63'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td>7. Практическая
                  значимость</td>
                <td>
                  7.1. Методическая разработка
                  обладает практической ценностью
                  для воспитательного процесса
                </td>
                <td>
                  <Select
                    name={'71'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  7.2. Методическая разработка может
                  быть адаптирована для реализации в
                  измененных условиях и использована
                  в практике других классных
                  руководителей
                </td>
                <td>
                  <Select
                    name={'72'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  7.3. Рекомендации по использованию 0-2
                  методической разработки
                  конструктивны и имеют
                  практическую ориентацию
                </td>
                <td>
                  <Select
                    name={'73'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td>8. Оформление</td>
                <td>
                  8.1. В содержании методической
                  разработки отсутствуют опечатки и
                  ошибки
                </td>
                <td>
                  <Select
                    name={'81'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  8.2. В методической разработке
                  корректно и грамотно используется
                  профессиональная терминология
                </td>
                <td>
                  <Select
                    name={'82'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td/>
                <td>
                  8.3. В оформлении методической
                  разработки целесообразно
                  использован иллюстративный
                  материал
                </td>
                <td>
                  <Select
                    name={'83'}
                    options={options}
                    placeholder={'0 - 2 ....'}
                  />
                </td>
              </tr>
              <tr>
                <td>9. Общее
                  впечатление от
                  конкурсного
                  материала</td>
                <td>
                  дополнительный балл, который
                  выставляется на усмотрение члена
                  жюри
                </td>
                <td>
                  <Select
                    name={'91'}
                    options={optionsOne}
                    placeholder={'0 - 1 ...'}
                  />
                </td>
              </tr>
            </tbody>
          </TableBox>
          <UploadButton
            type={'submit'}
          >Отправить</UploadButton>
        </form>
      </ModeratorContent>
    </ModeratorContainer>
  )
}