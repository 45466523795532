import React from "react";
import {MemberContainer} from "../../Style/Admin/AdminPanel";
import {useSelector} from "react-redux";
import {CompetitorProfile} from "../../containers/CompetitorsSteps/CompetitorProfile";
import {CompetitorWorks} from "../../containers/CompetitorsSteps/CompetitorWorks";

export const CompetitorView = () => {
  const competitorState = useSelector(state => state.competitor)



  switch (competitorState.step) {
    case 'works': return (
      <MemberContainer>
        <CompetitorWorks/>
      </MemberContainer>
    )
    default: return (
      <MemberContainer>
        <CompetitorProfile/>
      </MemberContainer>
    )
  }
}