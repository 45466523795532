import React from "react";
import {
  FooterContainer,
  FooterContent,
  FooterLogo,
  FooterLogoBox,
  FooterLogoContent,
  FooterMenu, FooterMenuLink
} from "../../Style/Footer";
import {NavLink} from "react-router-dom";
import {DropDownText, HeaderDropDown, HeaderDropDownBox, HeaderMenuLink} from "../../Style/Header";

export const Footer = () => {

  return (
    <FooterContainer>
      <FooterContent>
        <FooterLogoBox>
          <FooterLogo/>
          <FooterLogoContent>
            <p>Контакты</p>
            <p>по вопросам проведения Конкурса:</p>
            {/*<p>Тел.: +7 (495) 969-26-17</p>*/}
            {/*<p>(доб. 1246)</p>*/}
            <p>e-mail - vkkr@guppros.ru</p>
            <p>тел. - +7 (495) 969-26-17</p>
            <p>(доб. 7526,7522,7502)</p>
            <p>Техническая поддержка:</p>
            <p>тел. - +7 (800) 200-91-85 (доб. 0)</p>
          </FooterLogoContent>
        </FooterLogoBox>

        <FooterMenu>
          <HeaderDropDownBox>
            <HeaderMenuLink>
              Конкурс
            </HeaderMenuLink>
            <HeaderDropDown>
              <NavLink to={'/about'}>
                <DropDownText>
                  О конкурсе
                </DropDownText>
              </NavLink>
              <NavLink to={'/topic'}>
                <DropDownText>
                  Тематические направления
                </DropDownText>
              </NavLink>
              <NavLink to={'/calendar'}>
                <DropDownText>Календарь</DropDownText>
              </NavLink>
            </HeaderDropDown>
          </HeaderDropDownBox>
          <NavLink to={'/docs'}>
            <FooterMenuLink>
              <p>документы</p>
            </FooterMenuLink>
          </NavLink>
          <NavLink to={'/news'}>
            <FooterMenuLink>
              <p>новости</p>
            </FooterMenuLink>
          </NavLink>
        </FooterMenu>
      </FooterContent>
    </FooterContainer>
  )
}
