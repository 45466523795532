import React, {useState} from "react";
import {AuthForm, AuthPageContainer, ButtonAuth} from "../Style/AuthPage";
import {DocsTypesTitle, DocsTypesTitleBg} from "../Style/DocumentsPage/DocumentsPage";
import {DisplayFlexCol} from "../Style/Pages";
import {Input, InputLabel, InputLabelText} from "../Style/UI";
import {useHttp} from "../hooks/http.hooks";

export const RegisterUser = () => {
  const {request} = useHttp()
  const [form, setForm] = useState({
    email: '', password: '',
  })

  const onChangeHandler = event => {
    setForm({...form, [event.target.name]: event.target.value})
  }

  const registerHandler = async () => {
    try {
      const data = await request('/api/auth/register', 'POST', {...form})
      console.log(data)
    } catch (e) {
      console.log(e)
    }
  }

  return(
    <AuthPageContainer>
      <AuthForm>
        <DocsTypesTitle>
          <DocsTypesTitleBg/>
          <DisplayFlexCol>
            <h2>Регистрация </h2>
            <h2>нового пользователя</h2>
          </DisplayFlexCol>
        </DocsTypesTitle>
        <InputLabel>
          <InputLabelText>Логин: </InputLabelText>
          <Input
            name='email'
            type="text"
            value={form.email}
            onChange={e => onChangeHandler(e)}
          />
        </InputLabel>
        <InputLabel>
          <InputLabelText>Пароль: </InputLabelText>
          <Input
            name='password'
            type="password"
            value={form.password}
            onChange={e => onChangeHandler(e)}
          />
        </InputLabel>

        <ButtonAuth onClick={() => registerHandler()}>Зарегистрировать</ButtonAuth>
      </AuthForm>
    </AuthPageContainer>
  )
}