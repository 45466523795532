import React, {useEffect} from "react";
import {
  AboutUserText,
  AdminPanelViewTitle, MemberContainer, MemberContent, UploadButton
} from "../../Style/Admin/AdminPanel";
import {InfoCardDiscription, InfoCardItem} from "../../Style/Admin/CompetitorsView";
import {useHttp} from "../../hooks/http.hooks";
import {useDispatch, useSelector} from "react-redux";
import {changeExpertData, setExpertData} from "../../Redux/actions/expertAction";
import {InputAdmin} from "../../Style/UI";
import {toast, ToastContainer} from "react-toastify";

export const ExpertInfoCard = () => {
  const expertData = useSelector(state => state.expert.expertData)
  const {request} = useHttp()
  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await request('/api/expert/getExpert', 'GET', null, {
          Authorization: `Bearer ${token}`,
        })
        dispatch(setExpertData(data))
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [request, token, dispatch])

  const onClickUploadData = async () => {
    try {
      const status = await request('/api/expert/uploadExpertData', 'POST', expertData, {
        Authorization: `Bearer ${token}`,
      })
      toast.info(status.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    } catch (e) {
      toast.error('Что то пошло не так', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <MemberContainer>
      <ToastContainer/>
      <MemberContent>
        <AdminPanelViewTitle>Информационная Карта Эксперта</AdminPanelViewTitle>
        <InfoCardItem>
          <InfoCardDiscription>Фамилия Имя Отчество Эксперта</InfoCardDiscription>
          <AboutUserText>{expertData.name}</AboutUserText>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Субъект Российской Федерации</InfoCardDiscription>
          <AboutUserText>{expertData.region}</AboutUserText>
        </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>Место работы</InfoCardDiscription>
            <InputAdmin
              type={'text'}
              placeholder={'Ваше место работы...'}
              value={expertData.workplace}
              name={'workplace'}
              required
              onChange={e => dispatch(changeExpertData(e.target.value, 'workplace'))}
            />
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>Должность</InfoCardDiscription>
            <InputAdmin
              type={'text'}
              placeholder={'Ваша должность...'}
              value={expertData.position}
              name={'position'}
              required
              onChange={e => dispatch(changeExpertData(e.target.value, 'position'))}
            />
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>Электронный адрес</InfoCardDiscription>
            <InputAdmin
              type={'text'}
              placeholder={'Ваш электронный адрес...'}
              value={expertData.email}
              name={'email'}
              required
              onChange={e => dispatch(changeExpertData(e.target.value, 'email'))}
            />
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>Телефон</InfoCardDiscription>
            <InputAdmin
              type={'text'}
              placeholder={'Ваш телефон...'}
              value={expertData.phone}
              name={'phone'}
              required
              onChange={e => dispatch(changeExpertData(e.target.value, 'phone'))}
            />
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>Почтовый адрес (с индексом)</InfoCardDiscription>
            <InputAdmin
              type={'text'}
              placeholder={'Ваш почтовый адрес...'}
              value={expertData.mail}
              name={'mail'}
              required
              onChange={e => dispatch(changeExpertData(e.target.value, 'mail'))}
            />
          </InfoCardItem>
        <UploadButton
          onClick={onClickUploadData}
        >
          Отправить</UploadButton>
      </MemberContent>
    </MemberContainer>
  )
}