import React, {useEffect, useRef, useState} from "react";
import {
  AdminPanelViewTitle,
  ModeratorContainer,
  ModeratorContent,
  SecButton,
  UploadButton
} from "../../Style/Admin/AdminPanel";
import {Pagination, PaginationButton, PaginationButtonCurrent, TableStyle} from "../../Style/TableStyles";
import {useTable, usePagination} from 'react-table'
import {useDispatch, useSelector} from "react-redux";
import {useHttp} from "../../hooks/http.hooks";
import {useLocation, useHistory} from 'react-router-dom'
import {setExportUserData, uploadModeratorUserData} from "../../Redux/actions/moderatorActions";
import {CSVLink} from "react-csv";
import {InfoCardDiscription, InfoCardItem, Width500px} from "../../Style/Admin/CompetitorsView";
import {InputAdmin} from "../../Style/UI";
import {toast, ToastContainer} from "react-toastify";
import Loader from "react-loader-spinner"
import {setLoading} from "../../Redux/actions/authActions";
import Select from "react-select";
import {regionOptions} from "../../Docs/select.options";


const curRegion = localStorage.getItem('curRegion') ? {value: localStorage.getItem('curRegion'), label: localStorage.getItem('curRegion')} : regionOptions[0]


export const AdminModeratorMember = () => {
  const {data, columns} = useSelector(state => state.moderator.usersOptions)
  const {search} = useLocation()
  const url = new URLSearchParams(search)
  const {token, loading} = useSelector(state => state.auth)
  const [value, setValue] = useState('')
  const [select, setSelect] = useState(curRegion)
  const dispatch = useDispatch()
  const {request} = useHttp()
  const history = useHistory()
  const CsvData = useSelector(state => state.moderator.exportUsersExcel)
  const csvRef = useRef(null)
  const [pageP, setPage] = useState(+url.get('p') || 1)
  const [maxPage, setMaxPage] = useState(1)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10}
    },
    usePagination
  )

  const exportHandler = async () => {
    try {
      const data = await request('/api/moderator/getExportUsers')
      // console.log(data)
      await dispatch(setExportUserData(data))
      csvRef.current.link.click()
    } catch (e) {
      console.log(e)
    }
  }

  const hindBySurname = async () => {
    try {
      const data = await request('/api/moderator/getUserBySurname', 'POST', {value}, {
        Authorization: `Bearer ${token}`
      })
      dispatch(uploadModeratorUserData(data.data))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true))
        const data = await request('/api/moderator/get_users', "POST", {pageP, region: select.value})
        dispatch(uploadModeratorUserData(data.data))
        setMaxPage(data.maxPage)
        dispatch(setLoading(false))
      } catch (e) {
        console.log(e)
        dispatch(setLoading(false))
      }
    }

    fetchData()
  }, [request, dispatch, pageP, select.value])

  const setUsersData = async () => {
    try {
      dispatch(setLoading(true))
      toast.info('Данные загружаются', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
      const data = await request('/api/moderator/get_users', "POST", {pageP, region: select.value})
      dispatch(uploadModeratorUserData(data.data))
      setMaxPage(data.maxPage)
      dispatch(setLoading(false))
    } catch (e) {
      console.log(e)
      dispatch(setLoading(false))
    }
  }

  const changeRegionHandler = async event => {
    setSelect(event)
    history.push(`/admin/users?p=${1}`)
    setPage(1)
    try {
      dispatch(setLoading(true))
      const data = await request('/api/moderator/get_users', "POST", {pageP: 1, region: event.value})
      dispatch(uploadModeratorUserData(data.data))
      setMaxPage(data.maxPage)
      localStorage.setItem('curRegion', event.value)
      dispatch(setLoading(false))
    } catch (e) {
      console.log(e)
      dispatch(setLoading(false))
    }
  }

  return (
    <ModeratorContainer>
      <ToastContainer/>
      <ModeratorContent>
        <AdminPanelViewTitle>Сводные данные об участниках конкурса</AdminPanelViewTitle>
        <InfoCardItem>
          <InfoCardDiscription>Поиск по фамилии</InfoCardDiscription>
          <Width500px>
            <InputAdmin
              type={'text'}
              placeholder={'Фамилия...'}
              name={'name'}
              value={value}
              onChange={e => setValue(e.target.value)}
              required
            />
          </Width500px>
        </InfoCardItem>
        <UploadButton
          onClick={hindBySurname}
        >
          Искать
        </UploadButton>
        <SecButton
          onClick={setUsersData}
        >
          Сбросить
        </SecButton>
        <InfoCardItem>
          <InfoCardDiscription>Субъект Российской Федерации</InfoCardDiscription>
          <Width500px>
            <Select
                value={select}
                options={regionOptions}
                placeholder={'Регион участника...'}
                name={'region'}
                required
                onChange={e => changeRegionHandler(e)}
            />
          </Width500px>
        </InfoCardItem>
        <TableStyle>
          <table {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
            </tbody>
          </table>
          {!loading || <Pagination><Loader type="ThreeDots" color="#f6a117" height={40} width={40} /></Pagination>}
          {loading || <Pagination>
            <PaginationButton
              disabled={pageP - 1 === 0}
              onClick={() => {
                history.push(`/admin/users?p=${pageP - 1}`)
                setPage(pageP - 1)
              }} >
              {'<'}
            </PaginationButton>
            <PaginationButton
              disabled={pageP - 1 === 0}
              onClick={() => {
                history.push(`/admin/users?p=${pageP - 1}`)
                setPage(pageP - 1)
              }}>
              {pageP - 1}
            </PaginationButton>
            <PaginationButtonCurrent>
              {pageP}
            </PaginationButtonCurrent>
            <PaginationButton
              disabled={pageP === maxPage + 1}
              onClick={() => {
                history.push(`/admin/users?p=${pageP + 1}`)
                setPage(pageP + 1)
              }}>
              {pageP + 1}
            </PaginationButton>

            <PaginationButton
              disabled={pageP === maxPage + 1}
              onClick={() => {
                history.push(`/admin/users?p=${pageP + 1}`)
                setPage(pageP + 1)
              }}>
              {'>'}
            </PaginationButton>
          </Pagination>}
        </TableStyle>
        <CSVLink
          ref={csvRef}
          data={CsvData.data}
          headers={CsvData.headers}
          filename={'Сводные данные.csv'}
        />
        <UploadButton onClick={exportHandler}>Выгрузка сводных данных</UploadButton>
      </ModeratorContent>
    </ModeratorContainer>
  )
}