import {CHANGE_EXPERT_DATA, SET_CHECK_WORKS, SET_EXPERT_DATA} from "../Types/expertTypes";

const initialState = {
  expertData: {
    email: "",
    region:  "",
    name: "",
    workplace: "",
    position: "",
    phone: "",
    mail: "",
  },
  worksData: {
    data: [],
    columns: [
      {
        Header: 'Код работы',
        accessor: 'code'
      },
      {
        Header: 'Файл на проверку',
        accessor: 'workFile'
      },
      {
        Header: 'Перейти к оцениванию',
        accessor: 'workId'
      },
      {
        Header: 'Баллы',
        accessor: 'score'
      },
    ]
  }
}

export const expertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPERT_DATA: return {...state, expertData: action.payload}
    case CHANGE_EXPERT_DATA: return {...state, expertData: {...state.expertData, [action.id]: action.payload }}
    case SET_CHECK_WORKS: return {...state, worksData: {...state.worksData, data: action.payload}}
    default: return state
  }
}