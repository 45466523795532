import React from "react";
import {HeaderLoginBox, HeaderLoginIcon} from "../../Style/Header";

export const HeaderLogin = () => {
  // const mokHandler = () => {
  //   toast.info('Скоро появится, в разработке!', {
  //     position: "top-right",
  //     autoClose: 2000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: false,
  //     draggable: true,
  //     progress: undefined,
  //   })
  // }

  return(
    <a href={'https://lk-vkkr.apkpro.ru/login'} target={'_blank'} rel="noopener noreferrer">
      <HeaderLoginBox>
        <HeaderLoginIcon/>
          <p
            // onClick={mokHandler}
          >Войти</p>
      </HeaderLoginBox>
    </a>
  )
}