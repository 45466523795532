import React from "react";
import {
  AdminPanelSidebarContainer,
  AdminPanesSidebarTitle, SidebarList, SidebarListBtn, SidebarMenu,
  UserImage,
  UserInfo,
  UserName, UserStatus
} from "../../Style/Admin/AdminPanel";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authorization, setToken} from "../../Redux/actions/authActions";
import {changeCompetitorStep} from "../../Redux/actions/competitiorActions";

export const AdminCompetitorSidebar = () => {
  const dispatch = useDispatch()
  const isDisabled = useSelector(state => state.competitor.isDisabled)
  const userData = useSelector(state => state.competitor.infoCard)
  const didUploadWork = useSelector(state => state.competitor.didUploadWork)
  const {cert} = useSelector(state => state.competitor)

  const onExitHandler = () => {

    localStorage.removeItem('userData')
    dispatch(setToken(''))
    dispatch(authorization())
    onChangeStep('profile')
  }

  const onChangeStep = step => {
    dispatch(changeCompetitorStep(step))
  }

  return(
    <AdminPanelSidebarContainer>
      <UserInfo>
        <UserImage><i className="far fa-user"/></UserImage>
        <UserName>{userData.surname} {userData.name} {userData.patronymic}</UserName>
      </UserInfo>
      <UserStatus>Ваш статус:</UserStatus>
      <AdminPanesSidebarTitle>Участник</AdminPanesSidebarTitle>

      <SidebarMenu>
        <SidebarList onClick={() => onChangeStep('profile')}>Информационная Карта</SidebarList>
        <SidebarList>
          <SidebarListBtn disabled={isDisabled || !didUploadWork} onClick={() => onChangeStep('works')}>Загрузка работы</SidebarListBtn>
        </SidebarList>
        <NavLink to={'/'}>
          <SidebarList>Главная</SidebarList>
        </NavLink>
        <NavLink to={'/calendar'}>
        <SidebarList>Календарь</SidebarList>
        </NavLink>
        <SidebarList onClick={() => onExitHandler()}>Выход</SidebarList>
        {
          cert &&
          <a href={`https://dppo.apkpro.ru/page/certificate?name=${userData.name}&surname=${userData.surname}&patronymic=${userData.patronymic}`} target='_blank' rel="noopener noreferrer">
            <SidebarList>Получить сертификат</SidebarList>
          </a>
        }


      </SidebarMenu>

    </AdminPanelSidebarContainer>
  )
}