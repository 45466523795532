import React from "react";
import {
  SetDocsActions, SetDocsActionView,
  SetDocsContainer,
  SetDocsProps,
  SetDoscDescription
} from "../../Style/DocumentsPage/DocumentsPage";

export const SetDocs = props => {

  return (
    <SetDocsContainer>
      <SetDoscDescription>{props.title}</SetDoscDescription>
     <SetDocsProps>Документ / {props.type}, {props.size}</SetDocsProps>
    <SetDocsActions>
        {/*<SetDocsActionView>*/}
          {/*<i className="fas fa-eye"/>*/}
         {/* <p>Смотреть</p>*/}
      {/*  </SetDocsActionView>*/}
       <a href={props.file} target='_blank' download={props.file} rel="noopener noreferrer">
        <SetDocsActionView>
         <i className="fas fa-download"/>
         <p>Скачать</p>
        </SetDocsActionView>
      </a>
      </SetDocsActions>
    </SetDocsContainer>
  )
}
