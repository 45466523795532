import React from "react";
import {AdminHeaderContainer} from "../../Style/Admin/AdminPanel";
import {NavLink} from "react-router-dom";
import {LayoutLogoImage, LayoutMenuLogo} from "../../Style/LayoutHeader";

export const AdminHeader = () => {

  return(
    <AdminHeaderContainer>
      <LayoutMenuLogo>
        <NavLink to={'/'}>
          <LayoutLogoImage/>
        </NavLink>
        <NavLink to={'/'}>
          <p>
            ВСЕРОССИЙСКИЙ КОНКУРС
          </p>
          <p>
            СРЕДИ КЛАССНЫХ РУКОВОДИТЕЛЕЙ НА ЛУЧШИЕ МЕТОДИЧЕСКИЕ РАЗРАБОТКИ ВОСПИТАТЕЛЬНЫХ МЕРОПРИЯТИЙ
          </p>
        </NavLink>
      </LayoutMenuLogo>
    </AdminHeaderContainer>
  )
}
