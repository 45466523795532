import styled from 'styled-components'

import docsTitleImg from '../../assets/svg/DocsTitleImg.png'
import {LayoutLogoImage} from "../LayoutHeader";

export const DocumentsPageContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`

export const BreadcrumbsItemLink = styled.span`
  color: #1cb4d6;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  
`
export const BreadcrumbsItem = styled.span`
  text-decoration: none;
  color: #052b46;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
`

export const DocumentsPageContent = styled.div`
  margin: 38px 70px;
`

export const DocsContainer = styled.div`
  margin-left: 97px;
  width: 100%;
  
  @media (max-width: 800px) {
    margin-left: 0;
  }
`
export const DocsTypesTitle = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 72px;
  & h2 {
    padding-left: 45px;
    color: #052b46;
    z-index: 2;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.81px;
  }
`
export const DocsTypesTitleBg = styled.div`
  position: absolute;
  z-index: -1;
  width: 95px;
  height: 69px;
  background-size: cover;
  background-image: url(${docsTitleImg});
`
export const SetDocsContainer = styled.div`
  margin-bottom: 43px;
`

export const WinDocsContainer = styled.div`
  margin-bottom: 43px;
  max-width: 300px;
`
export const SetDoscDescription = styled.div`
  color: #052b46;
  font-family: Roboto, sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
`
export const SetDocsProps = styled.div`
  margin: 13px 0;
  color: #9fa8b8;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.12px;
  letter-spacing: 0.9px;
`
export const SetDocsActions = styled.div`
  display: flex;
  
  @media (max-width: 926px) {
    flex-direction: column;
    
  }
`
export const SetDocsActionView = styled.div`
  cursor: pointer;
  color: #1cb4d6;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.12px;
  text-transform: uppercase;
  margin-right: 32px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  
  & i {
    padding-right: 10px;
    font-size: 16px;
  }
`

export const DocsContent = styled.div`
  margin-bottom: 75px;
`
export const OrgDocs = styled.div`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`

export const OrgDocsCol = styled.div`
  max-width: 436px;
  
  @media (max-width: 1000px) {
    max-width: 800px;
  }
`

export const InsertToTitle = styled.h2`
  color: #052b46;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 35px;
  line-height: 26px;
`

export const BanerBox = styled.div`
  width: 400px;
  padding-right: 20px;
  margin-right: 20px;
  height: 120px;
  border: 1px solid #bababa;
  display: flex;    
  align-items: center;
  margin-bottom: 30px;
  
  & > div {
    display: flex;
  }
  
  & p {
    color: #053957;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.09px;
    text-transform: uppercase;
  }
  
  & ${LayoutLogoImage} {
    width: 93px;
    margin-left: 25px;
  }
`

export const BanerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
`

export const AhrefField = styled.h3`
  max-width: 500px;
  color: #1cb4d6;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.12px;
  text-transform: uppercase;
  margin-bottom: 30px;
`

