import React from "react";
import {
  NewsCompetitionsBox,
  NewsCompetitionsTitle,
  NewsImage,
  NewsList,
  NewsListBox,
  NewsListImage
} from "../../Style/HomePage/News";
import {Button} from "../UI/Button";
import {NavLink} from "react-router-dom";
import {news} from "../../Docs/NewsDoc";
import {NewsLink} from "./NewsLink";

export const NewsCompetitions = () => {

  return(
    <NewsCompetitionsBox>
      <NewsCompetitionsTitle>Новости конкурса</NewsCompetitionsTitle>
      <NewsListBox>
        <NewsListImage>
          <NewsImage/>
          <NewsLink data={news[0].data} date={news[0].date}/>
        </NewsListImage>
        <NewsList>
          <NewsLink data={news[1].data} date={news[1].date}/>
          <NewsLink data={news[2].data} date={news[2].date}/>
          <NavLink to={'/news'}>
            <Button value={'все новости'}/>
          </NavLink>
        </NewsList>
      </NewsListBox>
    </NewsCompetitionsBox>
  )
}