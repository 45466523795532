import React from "react";
import {ButtonBox} from "../../Style/UI";

export const Button = props => {

  return (
    <ButtonBox height={props.height} type={props.type}>
      <p>{props.value}</p>
    </ButtonBox>
  )
}