import styled from 'styled-components'
import {PageTitle} from "./Pages";

export const UpperNewsContainer = styled.div`
  margin-bottom: 82px;
`;

export const NewsTaskContainer = styled.div``

export const NewsText = styled.div`
  margin-bottom: 80px;
  margin-right: 100px;
  font-size: 16px;
  padding-top: 30px;
  line-height: 20px;
  color: #4e515d;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: left;
  & > div > p {
    margin-bottom: 40px;
    font-size: 30px;
    letter-spacing: 1px;
    color: #232c5f;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
  & > div > ul {
    padding-left: 90px;
  }
  & > div > ul > li {
    list-style-type: none;
    position: relative;
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 24px;
    color: #3d4669;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    &:before {
      content: "";
      position: absolute;
      height: 15px;
      width: 15px;
      left: -25px;
      top: 5px;
      background-color: #00bbd5;
    }
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 300px;
  height: 250px;
`;

export const CustomText = styled.div`
  margin-left: 30px;
  
  & p {
    font-size: 16px;
    line-height: 22px;
    color: rgb(61, 70, 105);
    font-family: Roboto, sans-serif;
    font-weight: 400;
  }
  
  & h2 {
    font-size: 20px;
    line-height: 22px;
    color: rgb(61, 70, 105);
    font-family: Roboto, sans-serif;
    font-weight: 600;
  }
`;

export const FullWidthContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #ddf3fe;
  &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 20vw solid transparent;
    border-right: 79vw solid transparent;
    border-bottom: 59px solid #ddf3fe;
    top: -58px;
  }
`;

export const AboutPageContainer = styled.div`
  margin-top: 30px;
  
  & ${PageTitle} {
    margin-left: 6px;
  }
`