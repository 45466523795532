import React from "react";
import {RoleButtonArea, RoleButtonBox, RoleButtonDescription, RoleButtonImage} from "../../Style/Header";

export const HeaderRoleButton = props => {

  return(
    <RoleButtonBox>
      <RoleButtonArea>
        <RoleButtonImage icon={props.icon}/>
      </RoleButtonArea>
      <RoleButtonDescription>
        {props.value}
      </RoleButtonDescription>
    </RoleButtonBox>
  )
}