import styled from 'styled-components';
import newsCalendar from '../../assets/img/news/newsCalendar.jpeg';

export const NewsContainer = styled.div`
  width: 100%;
`;

export const ResultHomeContainer = styled.div`
  width: 100%;
`;

export const ResultHomeContent = styled.div`
  max-width: 1280px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ResultHomeBox = styled.div`
  margin-left: 45px;
`;

export const ResultHomeText = styled.div`
  margin: 30px 40px 0;
  padding-bottom: 20px;
  color: #052b46;
  font-size: 20px;

  & p {
    margin-bottom: 20px;
    text-align: justify;
  }
`;

export const ResultHomeInfo = styled.div`
  margin: 10px 40px;
  padding-bottom: 40px;
  color: #052b46;
  font-size: 20px;
  border-bottom: 1px solid black;

  & h3 {
    text-align: center;
  }
  & a {
    text-align: center;
    display: block;
  }

  & p {
    margin: 20px 0;
    text-align: justify;
    text-indent: 25px;
  }
  & a {
    margin-left: 25px;
    text-decoration: underline;
  }
`;

export const ResultHomeButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const NewsContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const NewsCompetitionsBox = styled.div`
  margin-left: 67px;
`;
export const NewsCalendarBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  margin-right: 20px;
`;

export const NewsCompetitionsTitle = styled.h1`
  margin-top: 68px;
  margin-bottom: 35px;
  color: #052b46;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Roboto Condensed', serif;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`;
export const NewsList = styled.div``;
export const NewsListImage = styled.div``;

export const CalendarLink = styled.p`
  color: #1795b1;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.4px;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.3s;
  margin-bottom: 25px;

  &:hover {
    color: #f6a117;
  }
`;

export const CalendarArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 250px;
  margin-bottom: 25px;
`;

export const CalendarDescription = styled.div`
  color: #052b46;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  max-width: 150px;
  line-height: 22px;
`;

export const CalendarDate = styled.div`
  color: #f6a016;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 83px;
  font-weight: 700;
  line-height: 66.28px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & div {
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 0.72px;
    width: 105px;
    height: 35px;
    background-color: #f6a016;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Hyphen = styled.div`
  width: 27px;
  height: 12px;
  background-color: #f6a016;
  margin-bottom: 30px;
`;

export const NewsImage = styled.div`
  background-image: url(${newsCalendar});
  width: 290px;
  height: 195px;
  background-size: cover;
  margin-right: 42px;
  margin-bottom: 20px;
`;

export const NewsLinkBox = styled.div`
  max-width: 300px;
  margin-bottom: 24px;

  & p {
    color: #052b46;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
  }

  & h2 {
    color: #f6a117;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;

    & span {
      font-size: 24px;
      letter-spacing: 1.35px;
    }
  }
`;

export const NewsListBox = styled.div`
  display: flex;
`;
