import React, {useState} from 'react';
import {AuthForm, AuthPageContainer, ButtonAuth} from "../Style/AuthPage";
import {DocsTypesTitle, DocsTypesTitleBg} from "../Style/DocumentsPage/DocumentsPage";
import {DisplayFlexCol} from "../Style/Pages";
import {InputAdmin} from "../Style/UI";
import {useHistory} from 'react-router-dom'
import {toast, ToastContainer} from "react-toastify";
import {useHttp} from "../hooks/http.hooks";
import {InfoCardDiscription, InfoCardItem, Width500px} from "../Style/Admin/CompetitorsView";
import Select from "react-select";
import {regionOptions, thematicOptions} from "../Docs/select.options";
import styled from 'styled-components'

const StyledCertForm = styled(AuthForm)`
  padding: 30px 30px;
    
  & .content {
    margin: 0 auto;
  }
  & ${DocsTypesTitle} {
    margin: 0 auto;
  }
  
  & ${InfoCardItem} {
    max-width: 500px;
  }
  
  & ${ButtonAuth} {
    min-width: 300px;
    margin: 0 auto;
  }
  
`

export const CertPage = () => {
  const {request} = useHttp()
  const history = useHistory()
  const [infoCard, setInfoCard] = useState({
    surname: '',
    region: '',
    thematic: ''
  })

  const onChangeHandler = (value, key) => {
    setInfoCard({...infoCard, [key]: value})
  }

  const onSubmitHandler = async () => {
    for (const key in infoCard) {
      if (infoCard[key] === '') {
       return toast.error("Заполните все поля", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
    }

    try {
      const data = await request(`/api/user/getCert`, 'POST', infoCard)
      if (data.message) {
        return toast.error(data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
      window.open(data.link, '_blank')
      history.push('/')

    } catch (e) {
      toast.error("Сертификат недоступен", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }

  }

  return (
    <AuthPageContainer>
      <StyledCertForm>
        <DocsTypesTitle>
          <DocsTypesTitleBg/>
          <DisplayFlexCol>
            <h2>Получение </h2>
            <h2>сертификата участника</h2>
          </DisplayFlexCol>
        </DocsTypesTitle>
        <div className={'content'}>
          <InfoCardItem>
            <InfoCardDiscription>Фамилия</InfoCardDiscription>
            <InputAdmin
              type={'text'}
              placeholder={'Ваша фамилия...'}
              value={infoCard.surname}
              name={'surname'}
              required
              onChange={e => onChangeHandler(e.target.value, 'surname')}
            />
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>Субъект Российской Федерации</InfoCardDiscription>
            <Width500px>
              <Select
                options={regionOptions}
                placeholder={'Ваш регион...'}
                value={{value: infoCard.region, label: infoCard.region}}
                name={'region'}
                required
                onChange={e => onChangeHandler(e.value, 'region')}
              />
            </Width500px>
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>Тематическое направление методической разработки воспитательного мероприятия</InfoCardDiscription>
            <Width500px>
              <Select
                options={thematicOptions}
                placeholder={'Тематическое направление...'}
                value={{value: infoCard.thematic, label: infoCard.thematic}}
                name={'thematic'}
                required
                onChange={e => onChangeHandler(e.value, 'thematic')}
              />
            </Width500px>
          </InfoCardItem>
        </div>

        <ButtonAuth onClick={onSubmitHandler}>Получить</ButtonAuth>
      </StyledCertForm>
      <ToastContainer />
    </AuthPageContainer>
  )
}