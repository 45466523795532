import {
  CHANGE_INFO_VALUE,
  CHANGE_STEP,
  CHANGE_UPLOAD_FILE,
  DESABLED_UPLOAD_FILE,
  IS_DESABLE_BUTTON, SET_CERT, UPLOAD_FETCH_DATA
} from "../Types/competitorsTypes";


export const changeCompetitorStep = step => {
  return {
    type: CHANGE_STEP,
    payload: step
  }
}

export const changeInfoCardValue = (value, id) => {
  return {
    type: CHANGE_INFO_VALUE,
    payload: value,
    id
  }
}

export const setCert = (bool) => {
  return {
    type: SET_CERT,
    payload: bool
  }
}

export const isAllValueSet = () => {
  return {
    type: IS_DESABLE_BUTTON
  }
}

export const changeUploadFile = (id, value) => {
  return {
    type: CHANGE_UPLOAD_FILE,
    payload: value,
    id
  }
}

export const isDesabledUploadFile = () => {
  return {
    type: DESABLED_UPLOAD_FILE
  }
}

export const uploadFetchedData = data => {
  return {
    type: UPLOAD_FETCH_DATA,
    payload: data
  }
}