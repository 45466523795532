import React from "react";
import {AboutCompetitionBackground, AboutCompetitionContent} from "../../Style/HomePage/AboutCompetition";
import {DescriptionCompetition} from "../../components/AboutCompetition/DescriptionCompetition";

export const AboutCompetition = () => {

  return (
    <AboutCompetitionBackground>
      <AboutCompetitionContent>
        <DescriptionCompetition/>
      </AboutCompetitionContent>
    </AboutCompetitionBackground>
  )
}