import {CHANGE_EXPERT_DATA, SET_CHECK_WORKS, SET_EXPERT_DATA} from "../Types/expertTypes";
import {UserModeratorLink, UserModeratorList} from "../../Style/Admin/AdminPanel";
import React from "react";
import {NavLink} from "react-router-dom";
import {server} from "../../config";


export const setExpertData = data => {
  return {
    type: SET_EXPERT_DATA,
    payload: data
  }
}

export const changeExpertData = (value, id) => {
  return {
    type: CHANGE_EXPERT_DATA,
    payload: value,
    id
  }
}

export const setCheckWorks = data => {
  const setCheckFile = node => (
    <UserModeratorLink href={`${server}` + node.checkFile} target='_blank' rel="noopener noreferrer">Файл работы .pdf</UserModeratorLink>
  )

  const setCheckWork = node => {
    if (node.checked) {
      return (
        <p>Работа проверена</p>
      )
    } else {
      return (
        <NavLink to={`/admin/check/${node.id}`}>
          <UserModeratorList>Перейти к оцениванию работы</UserModeratorList>
        </NavLink>
      )
    }
  }

  const state = []
  data.forEach((node) => {
    state.push({
      code: `Код работы ` + node.id.slice(-3).toUpperCase(),
      workFile: setCheckFile(node),
      score: node.resultScore,
      workId: setCheckWork(node)
    })
  })
  return {
    type: SET_CHECK_WORKS,
    payload: state
  }
}