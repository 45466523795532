import React, {useEffect} from "react";
import {Header} from "../containers/HomePage/Header";
import {News} from "../containers/HomePage/News";
import {AboutCompetition} from "../containers/HomePage/AboutCompetition";
import {Partners} from "../components/Partners/Partners";
import {Footer} from "../containers/HomePage/Footer";
import {ResultHome} from "../containers/HomePage/ResultHome";

export const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return(
    <>
      <Header/>
      <News/>
      <ResultHome/>
      <AboutCompetition/>
      <Partners/>
      <Footer/>
    </>
  )
}