import React from "react";
import {NewsLinkBox} from "../../Style/HomePage/News";

export const NewsLink = props => {

  return(
    <NewsLinkBox>
      <h2><span>{props.date.day}</span> {props.date.mon} <span>{props.date.year}</span>{props.date.time}</h2>
      <p>{props.data.title}</p>
    </NewsLinkBox>
  )
}