import React from "react";
import {
  LayoutHeaderContainer,
  LayoutHeaderContent,
  LayoutLogoImage,
  LayoutMenu,
  LayoutMenuLogo
} from "../../Style/LayoutHeader";
import {HeaderMenu} from "../../components/Header/HeaderMenu";
import {HeaderLogin} from "../../components/Header/HeaderLogin";
import {HeaderRoleButtons} from "../../components/Header/HeaderRoleButtons";
import {NavLink} from "react-router-dom";

export const LayoutHeader = () => {

  return (
    <LayoutHeaderContainer>
      <LayoutHeaderContent>
        <LayoutMenu>

            <LayoutMenuLogo>
              <NavLink to={'/'}>
                <LayoutLogoImage/>
              </NavLink>
              <NavLink to={'/'}>
                <p>
                  IV ВСЕРОССИЙСКИЙ КОНКУРС
                  </p>
                <p>
                  СРЕДИ КЛАССНЫХ РУКОВОДИТЕЛЕЙ НА ЛУЧШИЕ МЕТОДИЧЕСКИЕ РАЗРАБОТКИ ВОСПИТАТЕЛЬНЫХ МЕРОПРИЯТИЙ
                </p>
              </NavLink>
            </LayoutMenuLogo>

          <HeaderMenu/>
          <HeaderLogin/>
        </LayoutMenu>
        <HeaderRoleButtons/>
      </LayoutHeaderContent>
    </LayoutHeaderContainer>
  )
}
