import styled from 'styled-components'

export const TableStyle = styled.div`
   padding: 1rem 0;

  table {
    border-spacing: 0;
    border: 1px solid #ced4e6;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    td {
      color: #052b46;
      vertical-align: top;
      min-height: 45px;
    }
    
    th,
    td {
      margin: 0;
      padding: .5rem 10px;
      border-bottom: 1px solid #ced4e6;
      text-align: start;
      font-family: "Roboto Condensed",sans-serif;
      font-size: 18px;
      font-weight: 400;
      color: #053957;

      :last-child {
        border-right: 0;
        vertical-align: middle;
      }
    }    
    
    
    th {
      white-space: nowrap;
      color: #f6a117;
      height: 60px;
      font-family: "Roboto Condensed",sans-serif;
      font-size: 18px;
      font-weight: 400;
    }
  }
`

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  
`
export const PaginationButton = styled.button`
  margin-left: 10px;
  background-color: #fff;
  border: none;
  font-family: "Roboto Condensed",sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #1cb4d6;
  cursor: pointer;
  transition: all .1s;
  outline: none;
  
  &:hover {
    color: #f6a117;
  }
  
  :disabled {
    cursor: default;
    color: #ced4e6;
    
    &:hover {
      color: #ced4e6;
    }
  }
  
  
`
export const PaginationButtonCurrent = styled.div`
  margin-left: 10px;
  font-family: "Roboto Condensed",sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #f6a117;
  cursor: default;
`
