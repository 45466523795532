import styled from 'styled-components'
import {PageTitle} from "../Pages";
import {NewsCompetitionsTitle} from "../HomePage/News";

export const NewsPageContainer = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  
  & ${PageTitle} {
    margin-left: 6px;
    margin-bottom: 30px;
  }
`

export const NewsPageContent = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 650px) {
    flex-wrap: wrap;
    
  }
`
export const NewsPageRow = styled.div`
  margin-right: 20px;
  
  & ${NewsCompetitionsTitle} {
    margin-top: 0;
  }
 
`
export const NewsPageRowLeft = styled.div`
  margin-right: 40px;
  width: 100%;
  
   @media (max-width: 650px) {
      margin-bottom: 30px;   
  }
  
  & ${NewsCompetitionsTitle} {
    margin-top: 0;
  }
 
`

export const NewsPageItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 40px;
`
export const NewsItemImage = styled.img`
  width: 220px;
  height: 165px;
  margin-bottom: 20px;
  margin-right: 30px;
  border: none;
`
export const NewsItemContent = styled.div`
  max-width: 480px;
  
  & h2 {
    color: #f6a117;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    
    & span {
      font-size: 24px;
      letter-spacing: 1.35px;
    }
  }
  
  & p {
    font-size: 15px;
    line-height: 22px;
    color: rgb(35, 44, 95);
    font-family: Roboto, sans-serif;
    font-weight: 400;
  }

`