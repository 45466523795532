import React from "react";
import {AdminPanelComponent, AdminPanelContainer, AdminPanelContent} from "../../Style/Admin/AdminPanel";
import {AdminHeader} from "../../components/AdminPanel/AdminHeader";
import {AdminModeratorSidebar} from "../../components/AdminPanel/AdminModeratorSidebar";
import {AdminModeratorMember} from "../AdminModeratorSteps/AdminModeratorMembers";
import {Route} from 'react-router-dom'
import {AdminModeratorExperts} from "../AdminModeratorSteps/AdminModeratorExperts";
import {AdminModeratorRating} from "../AdminModeratorSteps/AdminModeratorRating";
import {AdminModeratorUserId} from "../AdminModeratorSteps/AdminModeratorUserId";
import {AdminModeratorExpertId} from "../AdminModeratorSteps/AdminModeratorExpertId";
import {AdminModeratorRegisterExpert} from "../AdminModeratorSteps/AdminModeratorRegisterExpert";
import {AdminModeratorRegisterUser} from "../AdminModeratorSteps/AdminModeratorRegisterUser";

export const AdminModerator = props => {

  return (
    <AdminPanelContainer>
      <AdminHeader/>
      <AdminPanelContent>
        <AdminModeratorSidebar/>
        <AdminPanelComponent>
          <Route path={`${props.match.url}/users`} exact component={AdminModeratorMember}/>
          <Route path={`${props.match.url}/experts`} exact component={AdminModeratorExperts}/>
          <Route path={`${props.match.url}/registerExpert`} exact component={AdminModeratorRegisterExpert}/>
          <Route path={`${props.match.url}/registerUser`} exact component={AdminModeratorRegisterUser}/>
          <Route path={`${props.match.url}/rating`} component={AdminModeratorRating}/>
          <Route path={`${props.match.url}/users/:id`} component={AdminModeratorUserId}/>
          <Route path={`${props.match.url}/experts/:id`} component={AdminModeratorExpertId}/>
        </AdminPanelComponent>
      </AdminPanelContent>
    </AdminPanelContainer>
  )
}