export const regionOptions = [
  { value: 'Республика Адыгея (Адыгея)', label: 'Республика Адыгея (Адыгея)' },
  { value: 'Республика Алтай', label: 'Республика Алтай' },
  { value: 'Республика Башкортостан', label: 'Республика Башкортостан' },
  { value: 'Республика Бурятия', label: 'Республика Бурятия' },
  { value: 'Республика Дагестан', label: 'Республика Дагестан' },
  { value: 'Республика Ингушетия', label: 'Республика Ингушетия' },
  { value: 'Кабардино-Балкарская Республика', label: 'Кабардино-Балкарская Республика' },
  { value: 'Республика Калмыкия', label: 'Республика Калмыкия' },
  { value: 'Карачаево-Черкесская Республика', label: 'Карачаево-Черкесская Республика' },
  { value: 'Республика Карелия', label: 'Республика Карелия' },
  { value: 'Республика Коми', label: 'Республика Коми' },
  { value: 'Республика Крым', label: 'Республика Крым' },
  { value: 'Республика Марий Эл', label: 'Республика Марий Эл' },
  { value: 'Республика Мордовия', label: 'Республика Мордовия' },
  { value: 'Республика Саха (Якутия)', label: 'Республика Саха (Якутия)' },
  { value: 'Республика Северная Осетия - Алания', label: 'Республика Северная Осетия - Алания' },
  { value: 'Республика Татарстан (Татарстан)', label: 'Республика Татарстан (Татарстан)' },
  { value: 'Республика Тыва', label: 'Республика Тыва' },
  { value: 'Удмуртская Республика', label: 'Удмуртская Республика' },
  { value: 'Республика Хакасия', label: 'Республика Хакасия' },
  { value: 'Чеченская Республика', label: 'Чеченская Республика' },
  { value: 'Чувашская Республика - Чувашия', label: 'Чувашская Республика - Чувашия' },
  { value: 'Алтайский край', label: 'Алтайский край' },
  { value: 'Забайкальский край', label: 'Забайкальский край' },
  { value: 'Камчатский край', label: 'Камчатский край' },
  { value: 'Краснодарский край', label: 'Краснодарский край' },
  { value: 'Красноярский край', label: 'Красноярский край' },
  { value: 'Пермский край', label: 'Пермский край' },
  { value: 'Приморский край', label: 'Приморский край' },
  { value: 'Ставропольский край', label: 'Ставропольский край' },
  { value: 'Хабаровский край', label: 'Хабаровский край' },
  { value: 'Амурская область', label: 'Амурская область' },
  { value: 'Архангельская область', label: 'Архангельская область' },
  { value: 'Астраханская область', label: 'Астраханская область' },
  { value: 'Белгородская область', label: 'Белгородская область' },
  { value: 'Брянская область', label: 'Брянская область' },
  { value: 'Владимирская область', label: 'Владимирская область' },
  { value: 'Волгоградская область', label: 'Волгоградская область' },
  { value: 'Вологодская область', label: 'Вологодская область' },
  { value: 'Воронежская область', label: 'Воронежская область' },
  { value: 'Ивановская область', label: 'Ивановская область' },
  { value: 'Иркутская область', label: 'Иркутская область' },
  { value: 'Калининградская область', label: 'Калининградская область' },
  { value: 'Калужская область', label: 'Калужская область' },
  { value: 'Кемеровская область - Кузбасс,', label: 'Кемеровская область - Кузбасс,' },
  { value: 'Кировская область', label: 'Кировская область' },
  { value: 'Костромская область', label: 'Костромская область' },
  { value: 'Курганская область', label: 'Курганская область' },
  { value: 'Курская область', label: 'Курская область' },
  { value: 'Ленинградская область', label: 'Ленинградская область' },
  { value: 'Липецкая область', label: 'Липецкая область' },
  { value: 'Магаданская область', label: 'Магаданская область' },
  { value: 'Московская область', label: 'Московская область' },
  { value: 'Мурманская область', label: 'Мурманская область' },
  { value: 'Нижегородская область', label: 'Нижегородская область' },
  { value: 'Новгородская область', label: 'Новгородская область' },
  { value: 'Новосибирская область', label: 'Новосибирская область' },
  { value: 'Омская область', label: 'Омская область' },
  { value: 'Оренбургская область', label: 'Оренбургская область' },
  { value: 'Орловская область', label: 'Орловская область' },
  { value: 'Пензенская область', label: 'Пензенская область' },
  { value: 'Псковская область', label: 'Псковская область' },
  { value: 'Ростовская область', label: 'Ростовская область' },
  { value: 'Рязанская область', label: 'Рязанская область' },
  { value: 'Самарская область', label: 'Самарская область' },
  { value: 'Саратовская область', label: 'Саратовская область' },
  { value: 'Сахалинская область', label: 'Сахалинская область' },
  { value: 'Свердловская область', label: 'Свердловская область' },
  { value: 'Смоленская область', label: 'Смоленская область' },
  { value: 'Тамбовская область', label: 'Тамбовская область' },
  { value: 'Тверская область', label: 'Тверская область' },
  { value: 'Томская область', label: 'Томская область' },
  { value: 'Тульская область', label: 'Тульская область' },
  { value: 'Тюменская область', label: 'Тюменская область' },
  { value: 'Ульяновская область', label: 'Ульяновская область' },
  { value: 'Челябинская област', label: 'Челябинская област' },
  { value: 'Ярославская область', label: 'Ярославская область' },
  { value: 'Москва - г.ф.з', label: 'Москва - г.ф.з' },
  { value: 'Санкт-Петербург - г.ф.з', label: 'Санкт-Петербург - г.ф.з' },
  { value: 'Севастополь - г.ф.з', label: 'Севастополь - г.ф.з' },
  { value: 'Еврейская автономная область', label: 'Еврейская автономная область' },
  { value: 'Ненецкий автономный округ', label: 'Ненецкий автономный округ' },
  { value: 'Ханты-Мансийский автономный округ - Югра', label: 'Ханты-Мансийский автономный округ - Югра' },
  { value: 'Чукотский автономный округ', label: 'Чукотский автономный округ' },
  { value: 'Ямало-Ненецкий автономный округ', label: 'Ямало-Ненецкий автономный округ' },
]

export const onPosition = [
  { value: '0-3 года', label: '0-3 года' },
  { value: '3-5 лет', label: '3-5 лет' },
  { value: '5-10 лет', label: '5-10 лет' },
  { value: '10-15 лет', label: '10-15 лет' },
  { value: '15-20 лет', label: '15-20 лет' },
  { value: '20-25 лет', label: '20-25 лет' },
  { value: 'Свыше 25 лет', label: 'Свыше 25 лет' },
]

export const thematicOptions = [
  { value: 'Гражданское воспитание', label: 'Гражданское воспитание' },
  { value: 'Патриотическое воспитание и формирование российской идентичности', label: 'Патриотическое воспитание и формирование российской идентичности' },
  { value: 'Духовное и нравственное воспитание', label: 'Духовное и нравственное воспитание' },
  { value: 'Приобщение к культурному наследию', label: 'Приобщение к культурному наследию' },
  { value: 'Популяризация научных знаний', label: 'Популяризация научных знаний' },
  { value: 'Физическое воспитание и формирование культуры здоровья', label: 'Физическое воспитание и формирование культуры здоровья' },
  { value: 'Трудовое воспитание и профессиональное самоопределение', label: 'Трудовое воспитание и профессиональное самоопределение' },
  { value: 'Экологическое воспитание', label: 'Экологическое воспитание' },
]

export const classesOptions = [
  { value: '1-4 класс (6-11 лет)', label: '1-4 класс (6-11 лет)' },
  { value: '5 класс (10-12 лет)', label: '5 класс (10-12 лет)' },
  { value: '6 класс (11-13 лет)', label: '6 класс (11-13 лет)' },
  { value: '7 класс (12-14 лет)', label: '7 класс (12-14 лет)' },
  { value: '8 класс (13-15 лет)', label: '8 класс (13-15 лет)' },
  { value: '9 класс (14-16 лет)', label: '9 класс (14-16 лет)' },
  { value: '10 класс (15-17 лет)', label: '10 класс (15-17 лет)' },
  { value: '11 класс (16-18 лет)', label: '11 класс (16-18 лет)' },
]