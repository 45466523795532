import React, {useRef} from "react";
import {
  MemberContent, UploadButton,
  UploadFileBox,
  UploadFileButton,
  UploadFileInput,
  UploadWorkContainer
} from "../../Style/Admin/AdminPanel";
import {
  CompetitorsViewTitle,
  InfoCardDiscription,
  InfoCardItem
} from "../../Style/Admin/CompetitorsView";
import {useDispatch, useSelector} from "react-redux";
import {changeUploadFile, isDesabledUploadFile} from "../../Redux/actions/competitiorActions";
import {toast, ToastContainer} from "react-toastify";
import {didUploadWork} from "../../Redux/actions/authActions";

export const CompetitorWorks = () => {
  const fileInfo = useSelector(state => state.competitor.uploadWorkFiles)
  const isDisabled = useSelector(state => state.competitor.isDisabledFilesUpload)
  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()
  const pdfWork = useRef(null)
  const pdfWorkNoTitle = useRef(null)
  const docWork = useRef(null)
  const otherFiles = useRef(null)

  const onChangeFile = (ref, id) => {
    let name = ''
    for (let i = 0; i <= ref.current.files.length - 1; i++) {
      name = name + ref.current.files[i].name + '\n'
    }
    dispatch(changeUploadFile(id, name))
    dispatch(isDesabledUploadFile())
  }

  const onSubmitFiles = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const response = await fetch('/api/user/uploadWork', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData
      })


      dispatch(didUploadWork(false))

      if (response.ok) {
        toast.info("Ваша работа сохранена", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      } else {
        toast.error("Произошла ошибка", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
    } catch (e) {
      console.log(e)
    }

  }

  return(
    <MemberContent>
      <ToastContainer/>
      <CompetitorsViewTitle>Загрузите конкурсные материалы</CompetitorsViewTitle>
      <UploadWorkContainer>
        <form  method="POST" encType="multipart/form-data" onSubmit={e => onSubmitFiles(e)}>
          <InfoCardItem>
            <InfoCardDiscription>
              Методическая разработка в формате .pdf
            </InfoCardDiscription>
            <UploadFileBox>
              <UploadFileButton>Загрузить файл</UploadFileButton>
              <UploadFileInput name={'pdfWork'} ref={pdfWork} accept={'.pdf'} type={'file'}  onChange={() => {
                onChangeFile(pdfWork, 'pdfWork')
              }}/>
            </UploadFileBox>
            <p>{fileInfo.pdfWork.title}</p>
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>
              Методическая разработка без титульного листа в формате .pdf
            </InfoCardDiscription>
            <UploadFileBox>
              <UploadFileButton>Загрузить файл</UploadFileButton>
              <UploadFileInput name={'pdfWorkNoTitle'} ref={pdfWorkNoTitle} accept={'.pdf'} type={'file'} onChange={() => {
                onChangeFile(pdfWorkNoTitle, 'pdfWorkNoTitle')
              }}/>
            </UploadFileBox>
            <p>{fileInfo.pdfWorkNoTitle.title}</p>
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>
              Методическая разработка в формате .doc (docx)
            </InfoCardDiscription>
            <UploadFileBox>
              <UploadFileButton>Загрузить файл</UploadFileButton>
              <UploadFileInput name={'docWork'} ref={docWork} accept={'.doc,.docx'} type={'file'} onChange={() => {
                onChangeFile(docWork, 'docWork')
              }}/>
            </UploadFileBox>
            <p>{fileInfo.docWork.title}</p>
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardDiscription>
              Приложение (не более 3) в формате .pdf
            </InfoCardDiscription>
            <UploadFileBox>
              <UploadFileButton>Загрузить файл</UploadFileButton>
              <UploadFileInput multiple={true} ref={otherFiles} name={'otherFiles'} accept={'.pdf'} type={'file'} onChange={() => {
                onChangeFile(otherFiles, 'otherFiles')
              }}/>
            </UploadFileBox>
            <p>{fileInfo.otherFiles.title}</p>
          </InfoCardItem>
          <UploadButton
            disabled={isDisabled}
            type={'submit'}
          >Отправить</UploadButton>
        </form>
        {/*<a href="/static/workFiles/5f74a91da8903c367cc199d4/Методическая разработка без титульного листа в формате.pdf" target='_blank'>посмотреть файл</a>*/}
      </UploadWorkContainer>
    </MemberContent>
  )
}