import React, {useEffect} from "react";
import {Col, Grid, Row} from "react-flexbox-grid";
import logo from '../assets/svg/logoBig.svg'
import {BreadcrumbsItem, BreadcrumbsItemLink, DocumentsPageContainer} from "../Style/DocumentsPage/DocumentsPage";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {DisplayFlex, PageTitle} from "../Style/Pages";
import {
  AboutPageContainer,
  CustomText,
  FullWidthContainer,
  Image, NewsTaskContainer, NewsText,
  UpperContainer,
  UpperNewsContainer
} from "../Style/AboutPage";



export const AboutPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <AboutPageContainer>
      <DocumentsPageContainer>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={' / '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>О конкурсе</BreadcrumbsItemLink>,
                  pathname: '/about'
                }}/>
              </Breadcrumbs>
            </Col>
            <Row>
              <Col lg={12}>
                <DisplayFlex>
                  <PageTitle>О конкурсе</PageTitle>
                </DisplayFlex>
              </Col>
            </Row>
          </Row>
        </Grid>
      </DocumentsPageContainer>
      <DocumentsPageContainer>
      <UpperNewsContainer>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <UpperContainer>
                <Image src={logo} alt="logo" />
                <CustomText>
                  <p>
                    Департамент подготовки, профессионального развития и социального обеспечения педагогических работников Минпросвещения России информирует о проведении IV Всероссийского дистанционного конкурса среди классных руководителей на лучшие методические разработки воспитательных мероприятий (далее – Конкурс).
                    </p>
                  <br/>
                  <p>
                   Конкурс проводится с целью выявления и распространения лучших методических разработок воспитательных мероприятий, реализуемых классными руководителями в общеобразовательных организациях Российской Федерации.
                   </p>
                  <br/>
                  <p>
                  Учредителем Конкурса является Министерство просвещения Российской Федерации.
                  </p>
                  <br/>
                  <p>
                  Оператор Конкурса – Федеральное государственное автономное образовательное учреждение дополнительного профессионального образования «Академия реализации государственной политики и профессионального развития работников образования Министерства просвещения Российской Федерации» (далее – Оператор Конкурса).
                  </p>
                  <p>
                  Конкурс включает два этапа проведения: региональный и федеральный в соответствии с Положением о Конкурсе.
                  </p>
                  <br/>
                  <p>
                  Сроки проведения регионального этапа Конкурса в субъектах Российской Федерации: с 27 марта по 14 августа 2023 года.
                  </p>
                  <br/>
                  <p>
                  К участию в Конкурсе приглашаются выполняющие функции классного руководителя педагогические работники общеобразовательных организаций, реализующих общеобразовательные программы, независимо от их организационно-правовой формы.
                  </p>
                  <br/>
                  <p>
                  Орган исполнительной власти субъекта Российской Федерации, осуществляющий государственное управление в сфере образования, назначает регионального координатора, уполномоченного взаимодействовать с Оператором Конкурса.
                  </p>
                  <br/>
                  <p>
                  C подробной информацией о порядке и условиях участия в Конкурсе можно ознакомиться в Положении о конкурсе, размещенном на официальном сайте конкурса в разделе «Документы».
                  </p>
                  <br/>
                  <h2>
                    Контакты для вопросов и обращений, связанных с проведением Конкурса – электронная почта vkkr@guppros.ru.
                  </h2>
                </CustomText>
              </UpperContainer>
            </Col>
          </Row>
        </Grid>
      </UpperNewsContainer>
      </DocumentsPageContainer>
      <FullWidthContainer>
        <NewsTaskContainer>
          <Grid fluid>
            <DocumentsPageContainer>
            <Row>
              <Col lg={12}>
                <NewsText>
                  <div>
                    <p>Задачи конкурса:</p>
                    <ul>
                      <li>
                        поддержка профессионального развития и социального статуса классных руководителей;
                      </li>
                      <li>
                        содействие совершенствованию методической компетентности классных руководителей;
                      </li>
                      <li>
                        содействие повышению качества воспитательной деятельности в общеобразовательных организациях.
                      </li>
                      <li>
                        формирование экспертного сообщества в области методического обеспечения воспитательной деятельности в общеобразовательных организациях.
                      </li>
                    </ul>
                  </div>
                </NewsText>
              </Col>
            </Row>
            </DocumentsPageContainer>
          </Grid>
        </NewsTaskContainer>
      </FullWidthContainer>
    </AboutPageContainer>
  )
}
