import React from "react";
import {LayoutHeader} from "../containers/HomePage/LayoutHeader";
import {Footer} from "../containers/HomePage/Footer";

export const MainLayout = ({children}) => {

  return (
    <>
    <LayoutHeader/>
      {children}
    <Footer/>
    </>
  )
}