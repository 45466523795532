import React from "react";
import {AuthForm, AuthPageContainer, AuthRemember, ButtonAuth} from "../Style/AuthPage";
import {Input, InputLabel, InputLabelText, SwitchInput, SwitchLabel, SwitchSlider} from "../Style/UI";
import {DisplayFlexCol, DisplayFlexCenter} from "../Style/Pages";
import {DocsTypesTitle, DocsTypesTitleBg} from "../Style/DocumentsPage/DocumentsPage";
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {
  authorization,
  changeLogin,
  changePassword,
  changeRemember,
  didUploadWork, setRole,
  setToken
} from "../Redux/actions/authActions";
import { ToastContainer, toast } from 'react-toastify'
import {useHttp} from "../hooks/http.hooks";
import {useHistory} from 'react-router-dom'
import {useAuth} from "../hooks/auth.hook";
import styled from 'styled-components'

const StyledLink = styled(Link)`
  margin: 0 auto;
  color: rgb(104, 115, 181);
  transition: all .3s;
  font-size: 20px;
  text-transform: uppercase;
  &:hover {
    color: #f6a117;
  }
`

export const AuthPage = () => {
  const authState = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const auth = useAuth()
  const {request} = useHttp()
  const history = useHistory()

  const loginHandler = async () => {
    try {
      const data = await request('/api/auth/login', 'POST', {
        email: authState.login,
        password: authState.password
      })

      auth.login(data.token, data.userId)
      dispatch(authorization(true))
      dispatch(setToken(data.token))
      dispatch(didUploadWork(data.didUploadWork))
      dispatch(setRole(data.role))

      if (authState.remember) {
        localStorage.setItem('userLogin', JSON.stringify({
          login: authState.login,
          password: authState.password
        }))
      } else {
        localStorage.removeItem('userLogin')
        dispatch(changeLogin(''))
        dispatch(changePassword(''))
      }
      if (data.role === 'moderator') {
        return history.push(`/admin/users`)
      }

      history.push(`/admin`)

    } catch (e) {
      console.log(e)
      toast.info("Пользователь не найден", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })

    }
    // try {
    //   const data = await request('/api/login_check', 'POST', {
    //     "username": authState.login,
    //     "password": authState.password
    //   })
    //
    //   dispatch(authorization(true))
    //   dispatch(setToken(data.token))
    //
    //   auth.login(data.token, 1)
    //

    //
    //   history.push(`/admin`)
    // } catch (e) {
    //   console.log(e)
    // }
  }

  return(
    <AuthPageContainer>

      <AuthForm>
        <DocsTypesTitle>
          <DocsTypesTitleBg/>
          <DisplayFlexCol>
          <h2>Вход </h2>
          <h2>в личный кабинет</h2>
          </DisplayFlexCol>
        </DocsTypesTitle>
        <InputLabel>
          <InputLabelText>Логин: </InputLabelText>
          <Input
            type="text"
            value={authState.login}
            onChange={e => dispatch(changeLogin(e.target.value))}
          />
        </InputLabel>
        <InputLabel>
          <InputLabelText>Пароль: </InputLabelText>
          <Input
            type="password"
            value={authState.password}
            onChange={e => dispatch(changePassword(e.target.value))}
          />
        </InputLabel>

        <DisplayFlexCenter>
        <SwitchLabel >
          <SwitchInput
            type="checkbox"
            checked={authState.remember}
            onChange={() => dispatch(changeRemember(!authState.remember))}
          />
            <SwitchSlider/>
        </SwitchLabel>
          <AuthRemember>Запомнить меня</AuthRemember>
        </DisplayFlexCenter>

        <ButtonAuth onClick={() => loginHandler()}>Войти</ButtonAuth>
        <br/>
        <StyledLink to={'/cert'}>
          Получить сертификат
        </StyledLink>
      </AuthForm>
      <ToastContainer />
    </AuthPageContainer>
  )
}