import React, {useRef} from "react";
import {
  MemberContent,
  UploadButton,
  UploadFileBox,
  UploadFileButton,
  UploadFileInput
} from "../../Style/Admin/AdminPanel";
import {
  CompetitorsViewDescription,
  CompetitorsViewTitle,
  InfoCardDiscription,
  InfoCardItem, Width500px
} from "../../Style/Admin/CompetitorsView";
import Select from "react-select";
import {classesOptions, onPosition, regionOptions, thematicOptions} from "../../Docs/select.options";
import {useDispatch, useSelector} from "react-redux";
import {changeInfoCardValue, isAllValueSet} from "../../Redux/actions/competitiorActions";
import {InputAdmin, TextArea} from "../../Style/UI";
import {toast, ToastContainer} from "react-toastify";
// import {useHttp} from "../../hooks/http.hooks";

export const CompetitorProfile = () => {
  const infoCard = useSelector(state => state.competitor.infoCard)
  const disabled = useSelector(state => state.competitor.isDisabled)
  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()
  const uplFile = useRef(null)
  const infoCardPdf = useRef(null)
  // const {request} = useHttp()


  const onChangeHandler = (value, id) => {
    dispatch(changeInfoCardValue(value, id))
    dispatch(isAllValueSet())
  }

  const onUploadFile = async (e, type) => {
    dispatch(changeInfoCardValue(e.target.files[0].name, type))
    dispatch(isAllValueSet())
  }

  // const uploadDataOnServer = async e => {
  //   e.preventDefault()
  //
  //   try {
  //     const data = await request(`/api/user/uploadData`, 'POST', infoCard, {
  //           Authorization: `Bearer ${token}`
  //         })
  //     console.log(data)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  const submitHandler = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    try {
      const response = await fetch('/api/user/uploadForm', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData
      })
      if (response.ok) {
        toast.info("Данные успешно отправлены", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      } else {
        toast.error("Произошла ошибка", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }



    } catch (e) {
      console.log(e)

    }
  }

  return(
    <MemberContent>
      <ToastContainer/>
      <CompetitorsViewTitle>Информационная карта Участника конкурса</CompetitorsViewTitle>
      <CompetitorsViewDescription>(заполните пожалуйста все обязательные поля)</CompetitorsViewDescription>

      <form method="POST" encType="multipart/form-data" onSubmit={e => submitHandler(e)}>
        <InfoCardItem>
          <InfoCardDiscription>Субъект Российской Федерации</InfoCardDiscription>
          <Width500px>
            <Select
              options={regionOptions}
              placeholder={'Ваш регион...'}
              value={{value: infoCard.region, label: infoCard.region}}
              name={'region'}
              required
              onChange={e => onChangeHandler(e.value, 'region')}
            />
          </Width500px>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>
            Название муниципального образования
          </InfoCardDiscription>
          <InputAdmin
            type={'text'}
            placeholder={'Город...'}
            value={infoCard.location}
            name={'location'}
            required
            onChange={e => onChangeHandler(e.target.value, 'location')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Фамилия</InfoCardDiscription>
          <InputAdmin
            type={'text'}
            placeholder={'Ваша фамилия...'}
            value={infoCard.surname}
            name={'surname'}
            required
            onChange={e => onChangeHandler(e.target.value, 'surname')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Имя</InfoCardDiscription>
          <InputAdmin
            type={'text'}
            placeholder={'Ваше имя...'}
            value={infoCard.name}
            name={'name'}
            required
            onChange={e => onChangeHandler(e.target.value, 'name')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Отчество</InfoCardDiscription>
          <InputAdmin
            type={'text'}
            required
            placeholder={'Ваше отчество...'}
            value={infoCard.patronymic}
            name={'patronymic'}
            onChange={e => onChangeHandler(e.target.value, 'patronymic')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>
            Место работы участника Конкурса
            <p>(наименование общеобразовательной организации в соответствии с Уставом)</p>
          </InfoCardDiscription>

          <InputAdmin
            type={'text'}
            required
            placeholder={'Место работы...'}
            value={infoCard.workplace}
            name={'workplace'}
            onChange={e => onChangeHandler(e.target.value, 'workplace')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Должность участника Конкурса</InfoCardDiscription>
          <InputAdmin
            type={'text'}
            required
            placeholder={'Должность...'}
            value={infoCard.position}
            name={'position'}
            onChange={e => onChangeHandler(e.target.value, 'position')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Стаж работы участника Конкурса в качестве классного руководителя</InfoCardDiscription>
          <Width500px>
            <Select
              options={onPosition}
              placeholder={'Стаж...'}
              value={{value: infoCard.experience, label: infoCard.experience}}
              name={'experience'}
              required
              onChange={e => onChangeHandler(e.value, 'experience')}
            />
          </Width500px>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>
            Почтовый адрес участника Конкурса
            <p>(с индексом)</p>
          </InfoCardDiscription>
          <InputAdmin
            type={'text'}
            required
            placeholder={'Почтовый адрес...'}
            value={infoCard.mail}
            name={'mail'}
            onChange={e => onChangeHandler(e.target.value, 'mail')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Электронная почта</InfoCardDiscription>
          <InputAdmin
            type={'email'}
            required
            placeholder={'Email...'}
            value={infoCard.email}
            name={'email'}
            onChange={e => onChangeHandler(e.target.value, 'email')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Контактный телефон (мобильный)</InfoCardDiscription>
          <InputAdmin
            type={'phone'}
            required
            placeholder={'Телефон...'}
            value={infoCard.phone}
            name={'phone'}
            onChange={e => onChangeHandler(e.target.value, 'phone')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Тематическое направление методической разработки воспитательного мероприятия</InfoCardDiscription>
          <Width500px>
            <Select
              options={thematicOptions}
              placeholder={'Тематическое направление...'}
              value={{value: infoCard.thematic, label: infoCard.thematic}}
              name={'thematic'}
              required
              onChange={e => onChangeHandler(e.value, 'thematic')}
            />
          </Width500px>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Тема методической разработки воспитательного мероприятия</InfoCardDiscription>
          <InputAdmin
            type={'text'}
            required
            placeholder={'Тема...'}
            value={infoCard.theme}
            name={'theme'}
            onChange={e => onChangeHandler(e.target.value, 'theme')}
          />
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Класс (возраст обучающихся), на который ориентировано воспитательное мероприятие</InfoCardDiscription>
          <Width500px>
            <Select
              options={classesOptions}
              placeholder={'Класс...'}
              value={{value: infoCard.classes, label: infoCard.classes}}
              name={'classes'}
              required
              onChange={e => onChangeHandler(e.value, 'classes')}
            />
          </Width500px>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>
            Аннотация методической разработки воспитательного мероприятия
            <p>(не более 500 знаков, включая пробелы)</p>
          </InfoCardDiscription>
          <TextArea
            value={infoCard.annotation}
            placeholder={'Аннотация...'}
            name={'annotation'}
            required
            onChange={e => onChangeHandler(e.target.value, 'annotation')}
          />
        </InfoCardItem>

        <InfoCardItem>
          <InfoCardDiscription>
            Согласие на обработку персональных данных (.pdf)
          </InfoCardDiscription>
          <UploadFileBox>
            <UploadFileButton>Загрузить файл</UploadFileButton>
            <UploadFileInput ref={uplFile} accept={'.pdf'} type={'file'} name={'agreePdf'} onChange={e => onUploadFile(e, 'agreePdf')}/>
          </UploadFileBox>
        </InfoCardItem>

        <InfoCardItem>
          <InfoCardDiscription>
            Информационная карта в формате (.pdf)
          </InfoCardDiscription>
          <UploadFileBox>
            <UploadFileButton>Загрузить файл</UploadFileButton>
            <UploadFileInput ref={infoCardPdf} accept={'.pdf'} type={'file'} name={'infoCardPdf'} onChange={e => onUploadFile(e, 'infoCardPdf')}/>
          </UploadFileBox>
        </InfoCardItem>

        <InfoCardItem>
          <InfoCardDiscription>
            Принять согласие на обработку персональных данных
          </InfoCardDiscription>
          <input
            type='checkbox'
            checked={infoCard.accept}
            value={infoCard.accept}
            name={'accept'}
            onChange={() => onChangeHandler(!infoCard.accept, 'accept')}
          />
        </InfoCardItem>

        <UploadButton
          type={'submit'}
          disabled={disabled}
          // onClick={e => uploadDataOnServer(e)}
        >Отправить</UploadButton>
      </form>
    </MemberContent>
  )
}