import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {ModeratorContainer, ModeratorContent} from "../../Style/Admin/AdminPanel";
import {useHttp} from "../../hooks/http.hooks";
import {AuthForm, ButtonAuth} from "../../Style/AuthPage";
import {DocsTypesTitle, DocsTypesTitleBg} from "../../Style/DocumentsPage/DocumentsPage";
import {DisplayFlexCol} from "../../Style/Pages";
import {Input, InputLabel, InputLabelText} from "../../Style/UI";

export const AdminModeratorRegisterUser = () => {
  const {request} = useHttp()
  const [form, setForm] = useState({
    email: '', password: '',
  })

  const onChangeHandler = event => {
    setForm({...form, [event.target.name]: event.target.value})
  }

  const registerHandler = async () => {
    try {
      const data = await request('/api/auth/register', 'POST', {...form})
      toast.info(data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    } catch (e) {
      console.log(e)
      toast.error("Произошла ошибка", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <ModeratorContainer>
      <ToastContainer/>
      <ModeratorContent>
        <AuthForm>
          <DocsTypesTitle>
            <DocsTypesTitleBg/>
            <DisplayFlexCol>
              <h2>Регистрация </h2>
              <h2>нового пользователя</h2>
            </DisplayFlexCol>
          </DocsTypesTitle>
          <InputLabel>
            <InputLabelText>Логин: </InputLabelText>
            <Input
              name='email'
              type="text"
              value={form.email}
              onChange={e => onChangeHandler(e)}
            />
          </InputLabel>
          <InputLabel>
            <InputLabelText>Пароль: </InputLabelText>
            <Input
              name='password'
              type="password"
              value={form.password}
              onChange={e => onChangeHandler(e)}
            />
          </InputLabel>

          <ButtonAuth onClick={() => registerHandler()}>Зарегистрировать</ButtonAuth>
        </AuthForm>
      </ModeratorContent>
    </ModeratorContainer>
  )
}