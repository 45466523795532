import styled from 'styled-components'
import {PageTitle} from "./Pages";

export const TopicPageContainer = styled.div`
  max-width: 1280px;
  margin: 30px auto;
  
  & ${PageTitle} {
    margin-bottom: 30px;
  }
`

export const Number = styled.span`
  color: #7db9d8;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-decoration: underline;
`;

export const TopicContent = styled.div`
  margin-left: 100px;
  
  @media (max-width: 600px) {
    margin-left: 0;
  }
  
  
`
export const TopicItem = styled.div`
  display: flex;
  align-items: center; 
  width: 100%;
  margin-bottom: 20px;
  
  & p {
    color: rgb(16, 33, 94);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
`