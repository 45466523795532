import styled from 'styled-components'

import footerLogo from '../assets/svg/footerLogo.svg'
import {HeaderMenuLink} from "./Header";

export const FooterContainer = styled.div`
  width: 100%;
  background-color: #074b73;
  padding-bottom: 40px;
`

export const FooterContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 570px) {
    flex-wrap: wrap;
  }
`

export const FooterLogoBox = styled.div`


`
export const FooterLogoContent = styled.div`
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 36px;
  margin-left: 65px;
  
  @media (max-width: 570px) {
    margin-left: 20px;
  }
`
export const FooterLogo = styled.div`
  width: 94px;
  height: 79px;
  margin-bottom: 43px;
  margin-top: 66px;
  margin-left: 77px;
  background-size: cover;
  background-image: url(${footerLogo});
  @media (max-width: 570px) {
    margin-left: 20px;
  }
`

export const FooterMenu = styled.div`
  margin-top: 92px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 750px;
  margin-right: 100px;
  margin-left: 80px;
  justify-content: space-between;
  
  & ${HeaderMenuLink} {
    color: #ffffff;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.72px;
    padding-right: 10px;
    transition: all .3s;
    
    &:hover {
     color: #f6a117;
    }
  }
  
  @media (max-width: 570px) {
    flex-direction: column;
    margin-top: 20px;
  }
`
export const FooterMenuLink = styled.div`
  cursor: pointer;  
  display: flex;
  align-items: baseline;
  max-height: 30px;
  
  &:hover {
    & p, i {
      color: #f6a117;
    }
  }
  
  & i {
    color: #fff;
    font-size: 20px;
    transition: all .3s;

  }
  & p {
    color: #ffffff;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.72px;
    padding-right: 10px;
    transition: all .3s;
  }
`