import React, {useEffect} from "react";
import {Col, Grid, Row} from "react-flexbox-grid";
import {
  AbsoluteDiv,
  AbsoluteDivStage, BorderContainerLower, CalendarDatesContainer, CalendarPageContainer, Dates, Description,
  OperatorPageContainer,
  StageContainer, StageTitle
} from "../Style/CalendarPage/CalendarPage";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {BreadcrumbsItem, BreadcrumbsItemLink, DocumentsPageContent} from "../Style/DocumentsPage/DocumentsPage";
import {DisplayFlex, PageTitle} from "../Style/Pages";



const datesFederal = [
  {
    date: "23 мая – 30 мая",
    desc: "Формирование списков участников и состава жюри."
  },
  {
    date: "1 июня – 9 июня",
    desc: "Размещение участниками конкурсных материалов."
  },
  {
    date: "10 июня – 30 июня",
    desc: "Техническая экспертиза конкурсных материалов."
  },
  {
    date: "3 июля – 31 июля",
    desc: "Работа жюри."
  },
  {
    date: "1 августа – 14 августа",
    desc: "Подведение итогов Конкурса."
  },
];


export const CalendarPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <CalendarPageContainer>
      <DocumentsPageContent>
      <Grid fluid>
        <Row>
          <Col lg={12}>
            <Breadcrumbs separator={' / '}>
              <Breadcrumb data={{
                title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                pathname: '/'
              }}/>
              <Breadcrumb data={{
                title: <BreadcrumbsItemLink>Календарь</BreadcrumbsItemLink>,
                pathname: '/calendar'
              }}/>
            </Breadcrumbs>
          </Col>
          <Row>
            <Col lg={12}>
              <DisplayFlex>
                <PageTitle>календарь</PageTitle>
              </DisplayFlex>
            </Col>
          </Row>
        </Row>
        <OperatorPageContainer>
          <Row>
            <Col lg={1} />
            <Col lg={10}>
              {/*<BorderContainer>*/}
              {/*  <StageContainer>*/}
              {/*    <AbsoluteDivStage color="#1795b1" />*/}
              {/*    <StageTitle>*/}
              {/*      Региональный этап и организационные мероприятия*/}
              {/*    </StageTitle>*/}
              {/*    <Dates>1 АПРЕЛЯ - 13 ОКТЯБРЯ</Dates>*/}
              {/*  </StageContainer>*/}
              {/*  {dates.map(item => (*/}
              {/*    <CalendarDatesContainer key={item.desc}>*/}
              {/*      <AbsoluteDiv color="#1795b1" />*/}
              {/*      <Dates>{item.date}</Dates>*/}
              {/*      <Description>{item.desc}</Description>*/}
              {/*    </CalendarDatesContainer>*/}
              {/*  ))}*/}
              {/*</BorderContainer>*/}
              <BorderContainerLower>
                <StageContainer style={{ marginTop: "70px" }}>
                  <AbsoluteDivStage color="#f6a117" />
                  <StageTitle>Региональный этап</StageTitle>
                  <Dates>27 марта – 22 мая 2023 года</Dates>
                </StageContainer>
              </BorderContainerLower>
              <BorderContainerLower>
                <StageContainer style={{ marginTop: "70px" }}>
                  <AbsoluteDivStage color="#f6a117" />
                  <StageTitle>ФЕДЕРАЛЬНЫЙ ЭТАП</StageTitle>
                  <Dates>23 мая – 14 августа 2023 года</Dates>
                </StageContainer>
                {datesFederal.map((item, _) => (
                  <CalendarDatesContainer key={new Date().toString() + _}>
                    <AbsoluteDiv color="#f6a117" />
                    <Dates>{item.date}</Dates>
                    <Description>{item.desc}</Description>
                  </CalendarDatesContainer>
                ))}
              </BorderContainerLower>
            </Col>
            <Col lg={1} />
          </Row>

      </OperatorPageContainer>
      </Grid>
      </DocumentsPageContent>
    </CalendarPageContainer>
  )
}
