import React, {useEffect, useState} from "react";
import {
  AboutUserItem,
  AboutUserText,
  AdminPanelViewTitle, DisUserButton, FileLink,
  ModeratorContainer,
  ModeratorContent, UploadButton
} from "../../Style/Admin/AdminPanel";
import {useHistory, useParams} from 'react-router-dom'
import {useHttp} from "../../hooks/http.hooks";
import {useDispatch, useSelector} from "react-redux";
import {clearUserData, saveUserDataById, setExpertsList} from "../../Redux/actions/moderatorActions";
import {InfoCardDiscription, Width500px} from "../../Style/Admin/CompetitorsView";
import {toast, ToastContainer} from "react-toastify";
import Select from "react-select";
import {server} from "../../config";

export const AdminModeratorUserId = () => {
  const {id} = useParams()
  const {userIdData, expertList} = useSelector(state => state.moderator)
  const {request} = useHttp()
  const dispatch = useDispatch()
  const {token} = useSelector(state => state.auth)
  const history = useHistory()
  const [value, setValue] = useState(null)

  useEffect(() => {
    const fetchUserById = async id => {
      try {
        const data = await request('/api/moderator/getUserById', 'POST', {id})

        dispatch(saveUserDataById(data))
        const expertsList = await request('/api/moderator/get_experts_select')
        dispatch(setExpertsList(expertsList))
      } catch (e) {
        console.log(e)
      }
    }
    fetchUserById(id)
    return () => {
      dispatch(clearUserData())
    }
  }, [id, request, dispatch])

  const deleteUser = async () => {
    try {
      const data = await request('/api/moderator/deleteUser', 'POST', {id}, {
        Authorization: `Bearer ${token}`
      })
      console.log(data)
      toast.info(data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
      setTimeout(() => {
        history.push('/admin/users')
      }, 1000)
    } catch (e) {
      toast.error("Произошла ошибка", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const setExpert = async () => {
    if (value === null) {
      toast.error('Не выбран Эксперт', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    } else {
      try {
        const data = await request('/api/moderator/setExpertToUser', 'POST', {
          userId: id,
          expertId: value
        },
          {
            Authorization: `Bearer ${token}`
          })

        toast.info(data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      } catch (e) {
        toast.error('Произошла ошибка', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  return (
    <ModeratorContainer>
      <ToastContainer/>
      <ModeratorContent>
        <AdminPanelViewTitle>Сводные данные об участнике конкурса</AdminPanelViewTitle>
        <AboutUserItem>
          <InfoCardDiscription>Фамилия Имя Отчество Участника</InfoCardDiscription>
          <AboutUserText>{userIdData.name}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Субъект Российской Федерации</InfoCardDiscription>
          <AboutUserText>{userIdData.region}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Название муниципального образования</InfoCardDiscription>
          <AboutUserText>{userIdData.location}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Место работы участника Конкурса</InfoCardDiscription>
          <AboutUserText>{userIdData.workplace}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Должность участника Конкурса</InfoCardDiscription>
          <AboutUserText>{userIdData.position}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Стаж работы участника Конкурса в качестве классного руководителя</InfoCardDiscription>
          <AboutUserText>{userIdData.experience}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Почтовый адрес участника Конкурса</InfoCardDiscription>
          <AboutUserText>{userIdData.mail}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Электронная почта</InfoCardDiscription>
          <AboutUserText>{userIdData.email}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Контактный телефон (мобильный)</InfoCardDiscription>
          <AboutUserText>{userIdData.phone}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Тематическое направление методической разработки воспитательного мероприятия</InfoCardDiscription>
          <AboutUserText>{userIdData.thematic}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Тема методической разработки воспитательного мероприятия</InfoCardDiscription>
          <AboutUserText>{userIdData.theme}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Класс (возраст обучающихся), на который ориентировано воспитательное мероприятие</InfoCardDiscription>
          <AboutUserText>{userIdData.classes}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Аннотация методической разработки воспитательного мероприятия</InfoCardDiscription>
          <AboutUserText>{userIdData.annotation}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Согласие на обработку персональных данных</InfoCardDiscription>
          <AboutUserText>{userIdData.agreePdf}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Методическая разработка без титульного листа в формате .pdf</InfoCardDiscription>
          {userIdData.workFiles.pdfWorkNoTitle ? <FileLink
            target='_blank'
            rel="noopener noreferrer"
            href={`${server}` + userIdData.workFiles.pdfWorkNoTitle.url}
          >
            {userIdData.workFiles.pdfWorkNoTitle.title}
          </FileLink>: <AboutUserText>Работа не загружена</AboutUserText>}
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Методическая разработка в формате .pdf</InfoCardDiscription>
          {userIdData.workFiles.pdfWork ? <FileLink
            target='_blank'
            rel="noopener noreferrer"
            href={`${server}` + userIdData.workFiles.pdfWork.url}
          >
            {userIdData.workFiles.pdfWork.title}
          </FileLink>: <AboutUserText>Работа не загружена</AboutUserText>}
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Методическая разработка в формате .doc (docx)</InfoCardDiscription>
          {userIdData.workFiles.docWork ? <FileLink
            target='_blank'
            rel="noopener noreferrer"
            href={`${server}` + userIdData.workFiles.docWork.url}
          >
            {userIdData.workFiles.docWork.title}
          </FileLink>: <AboutUserText>Работа не загружена</AboutUserText>}
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Приложения</InfoCardDiscription>
          {Array.isArray(userIdData.workFiles.otherFiles) ?
            userIdData.workFiles.otherFiles.map((node, i) => (
              <div key={new Date().toString() + i}>
                <FileLink
                  target='_blank'
                  rel="noopener noreferrer"
                  href={`${server}` + node.url}
                >
                  {node.title}
                </FileLink>
                <br/>
              </div>
            ))
            : <AboutUserText>Приложения не загружены</AboutUserText>}
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Назначить эксперта</InfoCardDiscription>
          <Width500px>
            <Select
              options={expertList}
              placeholder={'Выберите эксперта...'}
              onChange={e => setValue(e.value)}
            />
          </Width500px>
          <InfoCardDiscription/>
          <UploadButton
            onClick={setExpert}
          >Назначить эксперта</UploadButton>
        </AboutUserItem>
        <AboutUserItem>
            <InfoCardDiscription>Отклонить работу участника</InfoCardDiscription>
          <DisUserButton
            onClick={deleteUser}
          >Отклонить</DisUserButton>
        </AboutUserItem>
      </ModeratorContent>
    </ModeratorContainer>
  )
}