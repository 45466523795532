import React from "react";
import {useSelector} from "react-redux";
import {AdminCompetitor} from "../containers/AdminPanels/AdminCompetitor";
import {AdminModerator} from "../containers/AdminPanels/AdminModerator";
import {AdminExpert} from "../containers/AdminPanels/AdminExpert";

export const AdminPanelConnector = props => {
  const userRole = useSelector(state => state.auth.role)



  switch (userRole) {
    case 'competitor':
      return <AdminCompetitor/>
    case 'moderator':
      return (
        <>
          <AdminModerator match={props.match}/>
        </>
        )

    case 'expert':
      return <AdminExpert match={props.match}/>
    default: return null

  }
}