import styled from 'styled-components'

import adminHeader from '../../assets/img/header/adminHeader.png'
import {LayoutMenuLogo} from "../LayoutHeader";

export const AdminPanelContainer = styled.div`
  
`
export const AdminPanelComponent = styled.div`
  margin-left: 110px;
  position: relative;
`
export const AdminPanelContent = styled.div`
  display: flex;
  margin-left: 170px;
  
  
`
export const AdminPanelSidebarContainer = styled.div`
  width: 250px;
  height: 500px;
`

export const MemberContainer = styled.div`
  position: absolute;
  margin-bottom: 50px;
  top: -180px;
  width: 780px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
`
export const ModeratorContainer = styled.div`
  position: absolute;
  margin-bottom: 50px;
  min-width: 1200px;
  top: -180px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
`

export const AdminHeaderContainer = styled.div`
  height: 170px;
  width: 100%;
  margin-bottom: 30px;
  background-size: cover;
  background-image: url(${adminHeader});
  
  
  & ${LayoutMenuLogo} {
    padding-top: 40px;
    padding-left: 130px;
    
    & p {
      color: #fff;
    }
  }
`

export const UserImage = styled.div`
  margin: 0 auto;
  width: 68px;
  border-radius: 50%;
  height: 68px;
  background-color: #79cce7;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & i {
    color: #fff;
    font-size: 28px;
    font-weight: 100;
  }
`

export const UserName = styled.p`
  text-align: center;
  color: #39404c;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.54px;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`

export const LogoutMessage = styled.div`
  cursor: pointer;
  color: #39404c;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.47px;
  border-bottom: 1px solid #39404c;
  transition: all .3s;
  
  &:hover {
    color: #f6a117;
    border-bottom: 1px solid #f6a117;
  }
`

export const AdminPanesSidebarTitle = styled.div`
  text-align: center;
  color: #3d4669;
  font-family: "Roboto Condensed", serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1.2px;
  line-height: 30.31px;
`

export const SidebarMenu = styled.ul`
  list-style: none;
  margin-top: 40px;
`
export const SidebarList = styled.li`
  border-bottom: 1px solid #ced4e6;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-top: 5px;
  cursor: pointer;
  color: #053957;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 400;
  transition: all .3s;
  
  &:hover {
    color: #f6a117;
  }
`
export const SidebarListBtn = styled.button`
  background-color: #fff;
  cursor: pointer;
  color: #053957;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 400;
  transition: all .3s;
  border: none;
  outline: none;
  
  &:hover {
    color: #f6a117;
  }
  
  &:disabled {
    color: #666;
    cursor: default;
  }
`

export const UserStatus = styled.p`
  margin-top: 30px;
  text-align: center;
  margin-bottom: 5px;
  color: #f6a117;
`

export const MemberContent = styled.div`
  margin: 30px 60px;
`

export const UploadWorkContainer = styled.div`
  margin-top: 50px;
`

export const UploadFileBox = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  
`

export const UploadFileButton = styled.button`
  border: 2px solid #f6a117;
  color: #f6a117;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    border: 2px solid #f6a117;
    color: #f6a117;
  }
`

export const UploadFileInput = styled.input`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
`

export const UploadButton = styled.button`
  width: 500px;
  height: 35px;
  background-color: #f6a117;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: .3s;
  outline: none;
  
  &:disabled {
    background-color: #666;
    cursor: default;
    
    &:hover {
      background-color: #666;
      cursor: default;
    }
  }
  
  :hover {
    box-shadow: 4px 4px 13px rgba(0,0,0,0.19);
    background-color: #1795b1
  }
`

export const SecButton = styled.button`
  width: 500px;
  height: 35px;
  background-color: #1795b1;
  margin-left: 30px;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: .3s;
  outline: none;
  
  &:disabled {
    background-color: #666;
    cursor: default;
    
    &:hover {
      background-color: #666;
      cursor: default;
    }
  }
  
  :hover {
    box-shadow: 4px 4px 13px rgba(0,0,0,0.19);
    background-color: #f6a117
  }
`

export const DisUserButton = styled.button`
  width: 500px;
  height: 35px;
  background-color: #1795b1;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: .3s;
  outline: none;
  
  &:disabled {
    background-color: #666;
    cursor: default;
    
    &:hover {
      background-color: #666;
      cursor: default;
    }
  }
  
  :hover {
    box-shadow: 4px 4px 13px rgba(0,0,0,0.19);
    background-color: #ff4f00;
  }
`

export const ModeratorContent = styled.div`
  padding: 30px; 
`

export const AdminPanelViewTitle = styled.h1`
  color: #052b46;
  font-family: "Roboto Condensed",sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 1rem;
`

export const UserModeratorList = styled.div`
  color: #1cb4d6;  
  transition: all .3s;
  
  &:hover {
    color: #f6a117;
  }  
`
export const UserModeratorLink = styled.a`
  color: #1cb4d6;  
  transition: all .3s;
  
  &:hover {
    color: #f6a117;
  }  
`


export const ExpertInfoBox = styled.div`
  color: #052b46;
  font-family: "Roboto Condensed",sans-serif;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  
  & h2 {
    margin-bottom: 10px;
  }
  
  & ${UserModeratorLink} {
    font-size: 20px;
  }
`

export const AboutUserItem = styled.div`
  padding-left: 1rem;
  margin-top: 30px;
`
export const AboutUserText = styled.div`
  color: #1cb4d6;
  font-family: Roboto,sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
`

export const FileLink = styled.a`
  color: #f6a117;
  font-family: Roboto,sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  transition: all .1s;
  
  &:hover {
    color: #1cb4d6;
  }
`