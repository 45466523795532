import React from "react";
import {HeaderRoleButton} from "../UI/HeaderRoleButton";
import {RoleButtonsBox} from "../../Style/Header";
import {NavLink} from "react-router-dom";

export const HeaderRoleButtons = () => {

  return (
    <RoleButtonsBox>
      <NavLink to={'/competitor'}>
        <HeaderRoleButton value={'участникам'} icon={'user'}/>
      </NavLink>
      <NavLink to={'/expert'}>
        <HeaderRoleButton value={'экспертам'} icon={'admin'}/>
      </NavLink>
      <NavLink to={'/regional'}>
        <HeaderRoleButton value={'региональным координаторам'} icon={'moder'}/>
      </NavLink>
    </RoleButtonsBox>
  )
}