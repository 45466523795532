import React from "react";
import {NewsItemContent, NewsItemImage, NewsPageItemContainer} from "../../Style/NewsPage/NewsPage";

export const NewsPageItem = props => {
  return (
    <NewsPageItemContainer>
      <NewsItemImage src={props.img}/>
      <NewsItemContent>
        <h2><span>{props.date.day}</span> {props.date.mon} <span>{props.date.year}</span>{props.date.time}</h2>
        <p>{props.data.description}</p>
      </NewsItemContent>
    </NewsPageItemContainer>
  )
}