import {
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_REMEMBER,
  SET_AUTH, SET_LOADING,
  SET_ROLE,
  SET_TOKEN,
  SET_USERID
} from "../Types/authTypes";

const auth = !!localStorage.getItem('userLogin')

const token = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).token : ''

const initialState = {
  isAuth: auth,
  login: '',
  password: '',
  remember: false,
  token: token,
  userId: null,
  loading: false,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLE: {
      return {...state, role: action.payload}
    }
    case CHANGE_LOGIN: {
      return {...state, login: action.payload}
    }
    case CHANGE_PASSWORD: {
      return {...state, password: action.payload}
    }
    case SET_AUTH: {
      return {...state, isAuth: action.payload}
    }
    case CHANGE_REMEMBER: {
      return {...state, remember: action.payload}
    }
    case SET_TOKEN: {
      return {...state, token: action.payload}
    }
    case SET_USERID: {
      return {...state, userId: action.payload}
    }
    case SET_LOADING: {
      return {...state, loading: action.payload}
    }
    default: return state
  }
}