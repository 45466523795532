import styled from 'styled-components'
import {DisplayFlexCol} from "./Pages";
import {DocsTypesTitle} from "./DocumentsPage/DocumentsPage";

export const AuthPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AuthForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f4f7fb;
  padding: 30px 60px 30px;
  
  & ${DisplayFlexCol} {
    margin-top: 50px;
  }
  & ${DocsTypesTitle} {
    margin-bottom: 20px;
  }
`

export const AuthRemember = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: #456;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  margin-left: 15px;
`

export const ButtonAuth = styled.button`
  cursor: pointer;
  font-size: 19px;
  font-weight: 400;
  color: #fff;
  background-color: #17bcd4;
  border: 1px solid #17bcd4;
  border-radius: 0.25rem;
  outline: none;
  text-transform: uppercase;
  transition: 0.3s;
  height: 50px;
  max-width: 340px;
  margin-top: 30px;
  
  &:hover {
    background-color: #1795b1;
  }
`
