import styled from 'styled-components'
import arrowDown from '../assets/svg/arrowDown.png'

export const ButtonBox = styled.div`
  height: 35px;
  margin: 5px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: ${props => props.height ? props.height : '135px'};
  background-color: ${props => {
    if (props.type === 'primary') {
      return '#17bcd4'
    } else {
      return '#182878'
    }
  }};
  border: ${props => {
    if (props.type === 'primary') {
      return 'none'
    } else {
      return '1px solid #b7c5d8'
    }
  }};
  transition: all .3s;

  &:hover {
    box-shadow: -2px 2px 11px -4px rgba(0,0,0,0.75);
    & p {
      color: #fff;
    }
  }

  & p {
    color: ${props => {
  if (props.type === 'primary') {
    return '#fff'
  } else {
    return '#17bcd4'
  }
    }};
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.25px;
    text-transform: uppercase;
    letter-spacing: 0.64px;
    transition: all .3s;
  }
`

export const ArrowDownBox = styled.div`
  width: 14px;
  height: 8px;
  padding-right: 2px;
  padding-bottom: 2px;
  background-size: cover;
  background-image: url(${arrowDown});
`

export const InputLabel = styled.label`
  margin-bottom: 10px;
`

export const Input = styled.input`
  padding: 8px;
  margin: 10px 0;
  font-size: 16px;
  min-width: 300px;
  border-radius: 4px;
  border: 1px solid #000;

  &:focus, :active {
    outline: none;
    border: 1px solid #4c579a;

`
export const InputAdmin = styled.input`
  padding: 8px;
  font-size: 15px;
  min-width: 485px;
  border-radius: 4px;
  border: 1px solid hsl(0,0%,80%);;

  &:focus, :active {
    outline: none;
    border: 1px solid #808080;
  }

  &::placeholder {
    font-size: 15px;
  }
`
export const InputLabelText = styled.p`
  font-size: 16px;
  color: #3d4669;
  font-family: "Roboto", serif;
  font-weight: 400;
`


export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: all .4s;
  border-radius: 34px;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);

  :before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

`

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;

`

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${SwitchSlider} {
    background-color: #f6a016;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);

  }
  &:checked + ${SwitchSlider}:before {
    transform: translateX(26px);
  }

  &:focus + ${SwitchSlider} {
    box-shadow: 0 0 1px #2196F3;

  }
`

export const TextArea = styled.textarea`
  resize: none;
  padding: 8px;
  font-size: 15px;
  min-width: 485px;
  border-radius: 4px;
  height: 100px;
  border: 1px solid hsl(0,0%,80%);;

  &:focus, :active {
    outline: none;
    border: 1px solid #808080;
  }

  &::placeholder {
    font-size: 15px;
  }
`

export const TableBox = styled.table`
  width: 100%;
  border-spacing: 0;
  margin: 30px 0;
  border: 1px solid #ced4e6;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: .5rem 10px;
      border-bottom: 1px solid #ced4e6;
      text-align: start;
      font-family: "Roboto Condensed",sans-serif;
      font-size: 18px;
      font-weight: 400;
      color: #053957;

      :last-child {
        border-right: 0;
      }
    }

    td {
      color: #052b46;
      vertical-align: top;
      min-height: 45px;
      max-width: 232px;
      padding-right: 100px;
      padding-bottom: 40px;
    }

    th {
      color: #f6a117;
      height: 60px;
      font-family: "Roboto Condensed",sans-serif;
      font-size: 18px;
      font-weight: 400;
      max-width: 200px;
      word-wrap: normal;
    }
`
export const TableCol = styled.div`
  min-width: calc(33.33%);
  max-width: calc(33.33% );
  display: flex;
  justify-content: center;
  border: 1px solid #000;
`
export const TableRow = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  justify-content: space-between;
`

export const TableHead = styled.div`

`
