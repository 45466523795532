import React, {useEffect} from "react";
import {ExpertContainer} from "../Style/MemberPage";
import {
  BreadcrumbsItem,
  BreadcrumbsItemLink, DocsContent,
  DocsTypesTitle, DocsTypesTitleBg,
  DocumentsPageContent
} from "../Style/DocumentsPage/DocumentsPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {PageTitle} from "../Style/Pages";
import { SetDocs } from "../components/Documents/SetDocs";
import regForm from '../assets/file/Forma_predstavleniya_informatsii_o_kandidature_v_sostav_zhyuri.docx'
import regList from '../assets/file/Obrazets_spiska_uchastnikov_federalnogo_etapa.docx'
//import regMain from '../assets/file/regionalMain2022.docx'
//import regExperts from '../assets/file/regionalExperts2022.docx'
//import regSpravka from '../assets/file/regionalSpravka2022.docx'
//import regOpd from '../assets/file/regionalOPD2022-1.docx'

export const RegionalPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return(
    <ExpertContainer>
      <DocumentsPageContent>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={'  /  '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>Региональным координаторам</BreadcrumbsItemLink>,
                  pathname: '/docs'
                }}/>
              </Breadcrumbs>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <PageTitle>Региональным координаторам</PageTitle>
            </Col>
          </Row>

          <Col lg={12}>
            <DocsTypesTitle>
              <DocsTypesTitleBg/>
              <h2>ДОКУМЕНТЫ</h2>
            </DocsTypesTitle>
          </Col>
          <Col lg={10} md={12}>
            <DocsContent>
              <SetDocs
                file={regForm}
                type={'DOC'}
                size={'14 Кб'}
                title={'Форма представления информации о кандидатуре в состав жюри'}
              />
              <SetDocs
                file={regList}
                type={'DOC'}
                size={'13 Кб'}
                title={'Образец списка участников федерального этапа'}
              />
            </DocsContent>
          </Col>
        </Grid>
      </DocumentsPageContent>
    </ExpertContainer>
  )
}
