import {DivMinWidth200} from "../../Style/Pages";
import React from "react";
import {
  CLEAR_EXPERT_ID_DATA,
  CLEAR_USER_ID_DATA, SET_EXPERTS_LIST, SET_EXPORT_USERS_DATA, SET_RATING_DATA,
  UPLOAD_EXPERT_DATA, UPLOAD_EXPERT_ID,
  UPLOAD_USER_DATA,
  UPLOAD_USER_DATA_BY_ID
} from "../Types/moderatorTypes";

const initialState = {
  usersOptions: {
    columns: [
      {
        Header: 'ФИО участника',
        accessor: 'memberName'
      },
      {
        Header: 'Субьект РФ',
        accessor: 'region'
      },
      {
        Header: 'Тематическое направление',
        accessor: 'thematic'
      },
      {
        Header: <DivMinWidth200>Тема работы</DivMinWidth200>,
        accessor: 'theme'
      },
      {
        Header: 'Конкурсные материалы',
        accessor: 'workFiles'
      },
      {
        Header: 'Эксперты',
        accessor: 'experts'
      },
      {
        Header: 'Общий балл',
        accessor: 'score'
      },
    ],
    data: []
  },
  expertOption: {
    data: [
      {
        memberName: '',
        region: '',
        workPlace: '',
        users: '',
        status: '',
      }
    ],
    columns: [
      {
        Header: 'ФИО эксперта',
        accessor: 'memberName'
      },
      {
        Header: 'Субьект РФ',
        accessor: 'region'
      },
      {
        Header: 'Место работы',
        accessor: 'workPlace'
      },
      {
        Header: 'Участники',
        accessor: 'users'
      },
      {
        Header: 'Статус проверки работы',
        accessor: 'status'
      }
    ]
  },
  ratingOption: {
    data: [
      {
        memberName: '',
        region: '',
        thematic: '',
        experts: '',
        expertScore: '',
        score: '',
      }
    ],
    columns: [
      {
        Header: 'ФИО участника',
        accessor: 'memberName'
      },
      {
        Header: 'Субьект РФ',
        accessor: 'region'
      },
      {
        Header: 'Тематическое направление',
        accessor: 'thematic'
      },
      {
        Header: 'Эксперты',
        accessor: 'experts'
      },
      {
        Header: 'Баллы',
        accessor: 'expertScore'
      },
      {
        Header: 'Средний общий балл',
        accessor: 'score'
      },
    ]
  },
  exportUsersExcel: {
    headers: [
      {label: 'Субъект Российской Федерации', key: 'region'},
      {label: 'Наименование муниципального образования (населенного пункта)', key: 'location'},
      {label: 'Фамилия, имя, отчество участника Конкурса (полностью)', key: 'name'},
      {label: 'Место работы участника Конкурса', key: 'workplace'},
      {label: 'Должность участника Конкурса', key: 'position'},
      {label: 'Стаж работы участника Конкурса в качестве классного руководителя', key: 'experience'},
      {label: 'Почтовый адрес участника Конкурса (с индексом)', key: 'mail'},
      {label: 'Электронная почта', key: 'email'},
      {label: 'Контактный телефон', key: 'phone'},
      {label: 'Тематическое направление методической разработки воспитательного мероприятия', key: 'thematic'},
      {label: 'Тема методической разработки воспитательного мероприятия', key: 'theme'},
      {label: 'Класс (возраст обучающихся)', key: 'classes'},
      {label: 'Эксперт 1 (баллы)', key: 'expert1'},
      {label: 'Эксперт 2 (баллы)', key: 'expert2'},
      {label: 'Эксперт 3 (баллы)', key: 'expert3'},
      {label: 'Общий средний балл', key: 'score'},
      {label: 'Статус', key: 'status'},
    ],
    // headers: [
    //   {label: 'id', key: 'id'},
    //   {label: 'Работа', key: 'work'},
    // ],
    // headers: [
    //   {label: 'id', key: 'id'},
    // ],
    // headers: [
    //   {label: 'Фамилия, имя, отчество участника Конкурса (полностью)', key: 'name'},
    //   {label: 'Субъект Российской Федерации', key: 'region'},
    //   {label: 'Тематическое направление', key: 'thematic'},
    //   {label: 'Эксперт 1', key: 'expert1'},
    //   {label: 'Оценка', key: 'score1'},
    //   {label: 'Эксперт 2', key: 'expert2'},
    //   {label: 'Оценка', key: 'score2'},
    //   {label: 'Эксперт 3', key: 'expert3'},
    //   {label: 'Оценка', key: 'score3'},
    //   {label: 'Общий средний балл', key: 'score'},
    // ],
    data: []
  },
  userIdData: {
    region: '',
    location: '',
    name: '',
    workplace: '',
    position: '',
    experience: '',
    mail: '',
    email: '',
    phone: '',
    thematic: '',
    theme: '',
    classes: '',
    annotation: '',
    agreePdf: '',
    workFiles: 'Работы еще не загружены',
  },
  expertIdData: {
    email: "",
    mail: "",
    name: "",
    phone: "",
    position: "",
    region: "",
    workplace: "",
  },
  expertList: [
  ]

}

export const moderatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_USER_DATA: return  {...state, usersOptions: {...state.usersOptions, data: action.payload} }
    case UPLOAD_USER_DATA_BY_ID: return  {...state, userIdData: action.payload}
    case CLEAR_USER_ID_DATA: return  {...state, userIdData: initialState.userIdData}
    case CLEAR_EXPERT_ID_DATA: return  {...state, expertIdData: initialState.expertIdData}
    case UPLOAD_EXPERT_DATA: return {...state, expertOption: {...state.expertOption, data: action.payload}}
    case UPLOAD_EXPERT_ID: return  {...state, expertIdData: action.payload}
    case SET_EXPORT_USERS_DATA: return  {...state, exportUsersExcel: {...state.exportUsersExcel, data: action.payload}}
    case SET_EXPERTS_LIST: return  {...state, expertList: action.payload}
    case SET_RATING_DATA: return {...state, ratingOption: {...state.ratingOption, data: action.payload}}
    default: return state
  }
}