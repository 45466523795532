import React, {useEffect} from "react";
import {
  AdminPanelViewTitle,
  ExpertInfoBox,
  ModeratorContainer,
  ModeratorContent,
  UserModeratorLink
} from "../../Style/Admin/AdminPanel";
import {useDispatch, useSelector} from "react-redux";
import {useHttp} from "../../hooks/http.hooks";
import {Pagination, PaginationButton, PaginationButtonCurrent, TableStyle} from "../../Style/TableStyles";
import {usePagination, useTable} from "react-table";
import {setCheckWorks} from "../../Redux/actions/expertAction";
import expertWork from '../../assets/file/expertWork.pdf'

export const ExpertWorkCheck = () => {
  const {data, columns} = useSelector(state => state.expert.worksData)
  const {token} = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const {request} = useHttp()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await request('/api/expert/getCurrentWork', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        dispatch(setCheckWorks(data))
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [request, token, dispatch])

  // const clickHandler = async () => {
  //   try {
  //     const data = request('/api/expert/setWorks', 'GET', null, {
  //       Authorization: `Bearer ${token}`
  //     })
  //     console.log(data)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex},
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 6}
    },
    usePagination
  )

  return (
    <ModeratorContainer>
      <ModeratorContent>
        <AdminPanelViewTitle>Проверка работ</AdminPanelViewTitle>
        <ExpertInfoBox>
          <h2>Памятка эксперту</h2>
          <UserModeratorLink href={expertWork} target='_blank' rel="noopener noreferrer">Порядок работы в личных кабинетах</UserModeratorLink>
          <UserModeratorLink style={{marginTop: '15px'}} href={'https://www.youtube.com/watch?v=DO9ax_fNY40&feature=youtu.be&ab_channel=%D0%90%D0%BA%D0%B0%D0%B4%D0%B5%D0%BC%D0%B8%D1%8F'} target='_blank' rel="noopener noreferrer">Вебинар для экспертов к конкурсу классных руководителей</UserModeratorLink>
        </ExpertInfoBox>

      <TableStyle>
        <table {...getTableProps()}>
          <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          </tbody>
        </table>
        <Pagination>
          <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </PaginationButton>
          <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            {pageIndex}
          </PaginationButton>
          <PaginationButtonCurrent>
            {pageIndex + 1}
          </PaginationButtonCurrent>
          <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
            {pageIndex + 2}
          </PaginationButton>
          <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </PaginationButton>
        </Pagination>
      </TableStyle>
      {/*<button onClick={clickHandler}>Задать</button>*/}
      </ModeratorContent>
    </ModeratorContainer>
  )
}