import React from "react";
import {DescriptionContainer, DescriptionIcon} from "../../Style/HomePage/AboutCompetition";

export const DescriptionCompetition = () => {

  return (
    <DescriptionContainer>
      <h1>о конкурсе
      </h1>
      <DescriptionIcon/>
      <p>
        Ключевые вопросы воспитания современного ребенка сегодня находятся в
        центре внимания государства, общества, педагогов и родителей. В связи с
        этим деятельность классных руководителей становится особенно значимой.
        Импульсом к формированию новых подходов к их деятельности послужило
        поручение Президента Российской Федерации по созданию системы
        мотивации классных руководителей в целях повышения эффективности
        воспитательной работы и социализации обучающихся в
        общеобразовательных организациях. Проведение Всероссийского
        дистанционного конкурса среди классных руководителей на лучшие
        методические разработки воспитательных мероприятий направлено на
        содействие профессиональному развитию классных руководителей,
        поддержку их деятельности, раскрытие творческого потенциала.
      </p>
    </DescriptionContainer>
  )
}