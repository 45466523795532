import React, {useEffect} from "react";
import {server} from '../config'

export const RedirectStatic = () => {

  useEffect(() => {
    const staticUrl = window.location.pathname
    window.location.replace(server + staticUrl)
  })


  return(
    <div/>
  )
}