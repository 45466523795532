import React, {useEffect} from "react";
import {CompetitorContainer} from "../Style/MemberPage";
import {
  BreadcrumbsItem,
  BreadcrumbsItemLink, DocsContent,
  DocsTypesTitle, DocsTypesTitleBg,
  DocumentsPageContent
} from "../Style/DocumentsPage/DocumentsPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {PageTitle} from "../Style/Pages";
import {SetDocs} from "../components/Documents/SetDocs";

//import competitorMain from '../assets/file/CompetitorMain.docx'
//import competitorData from '../assets/file/CompetitorAgree2022.docx'
//import competitorDataRpd from '../assets/file/CompetitorAgreeRPD2022.docx'
import personAdd from '../assets/file/Struktura_metodicheskoi_razrabotki.pdf'
import sameList from '../assets/file/Spisok_tematicheskikh_napravleniy_Konkursa.pdf'
//import gide from '../assets/file/gide.pdf'
export const CompetitorPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return(
    <CompetitorContainer>
      <DocumentsPageContent>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={' / '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>Участникам</BreadcrumbsItemLink>,
                  pathname: '/docs'
                }}/>
              </Breadcrumbs>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <PageTitle>Участникам</PageTitle>
            </Col>
          </Row>

          <Col lg={12}>
            <DocsTypesTitle>
              <DocsTypesTitleBg/>
              <h2>ДОКУМЕНТЫ</h2>
            </DocsTypesTitle>
          </Col>
          <Col lg={10} md={12}>
            <DocsContent>
            <SetDocs
                file={sameList}
                type={'PDF'}
                size={'336 Кб'}
                title={'Список тематических направлений'}
              />
              <SetDocs
                file={personAdd}
                type={'PDF'}
                size={'407 Кб'}
                title={'Структура методической разработки'}
              />
              </DocsContent>
          </Col>
        </Grid>
      </DocumentsPageContent>
    </CompetitorContainer>
  )
}
