import React from "react";
import {PartnersBox, PartnersContainer, PartnersContent, PartnersImage, PartnersItem} from "../../Style/HomePage/Partners";


export const Partners = () => {

  return (
    <PartnersContainer>
      <PartnersContent>
        <h1>Учредители и организаторы</h1>
        <PartnersBox>
          <a href="https://edu.gov.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'minPros'}/>
              <p>МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ
                РОССИЙСКОЙ ФЕДЕРАЦИИ</p>
            </PartnersItem>
          </a>
          <a href="https://apkpro.ru" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'fgyp'}/>
              <p>Академия Минпросвещения России</p>
            </PartnersItem>
          </a>
          <a href="https://vestnik.edu.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'vestnik'}/>
            </PartnersItem>
          </a>
          <a href="http://www.edu.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'rusPortal'}/>
              <p>Федеральный портал "Российское образование"</p>
            </PartnersItem>
          </a>
        </PartnersBox>
      </PartnersContent>
    </PartnersContainer>
  )
}