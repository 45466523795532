import React, {useEffect} from "react";
import {
  AboutUserItem,
  AboutUserText,
  AdminPanelViewTitle,
  ModeratorContainer,
  ModeratorContent
} from "../../Style/Admin/AdminPanel";
import {InfoCardDiscription} from "../../Style/Admin/CompetitorsView";
import {useParams} from "react-router-dom";
import {useHttp} from "../../hooks/http.hooks";
import {useDispatch, useSelector} from "react-redux";
import {clearExpertData, uploadExpertById} from "../../Redux/actions/moderatorActions";

export const AdminModeratorExpertId = () => {
  const {id} = useParams()
  const expert = useSelector(state => state.moderator.expertIdData)
  const {request} = useHttp()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async id => {
      try {
        const data = await request('/api/moderator/getExpertById', 'POST', {id})
        console.log(id, data)
        dispatch(uploadExpertById(data))
      } catch (e) {
        console.log(e)
      }
    }
    fetchData(id)
    return () => {
      dispatch(clearExpertData())
    }
  }, [id, request, dispatch])

  return (
    <ModeratorContainer>
      <ModeratorContent>
        <AdminPanelViewTitle>Сводные данные о эксперте конкурса</AdminPanelViewTitle>
        <AboutUserItem>
          <InfoCardDiscription>Фамилия Имя Отчество Эксперта</InfoCardDiscription>
          <AboutUserText>{expert.name}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Субъект Российской Федерации</InfoCardDiscription>
          <AboutUserText>{expert.region}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Место работы эксперта Конкурса</InfoCardDiscription>
          <AboutUserText>{expert.workplace}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Должность эксперта Конкурса</InfoCardDiscription>
          <AboutUserText>{expert.position}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Контактный телефон (мобильный)</InfoCardDiscription>
          <AboutUserText>{expert.phone}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Электронная почта</InfoCardDiscription>
          <AboutUserText>{expert.email}</AboutUserText>
        </AboutUserItem>
        <AboutUserItem>
          <InfoCardDiscription>Почтовый адрес эксперта Конкурса</InfoCardDiscription>
          <AboutUserText>{expert.mail}</AboutUserText>
        </AboutUserItem>
      </ModeratorContent>
    </ModeratorContainer>
  )
}