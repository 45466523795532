import styled from 'styled-components'
import aboutBg1900 from '../../assets/img/about/aboutBg1900.png'
import aboutBg1200 from '../../assets/img/about/aboutBg.png'
import aboutIcon from '../../assets/svg/DescriptionIcon.png'

export const AboutCompetitionContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  height: 460px;
  background-size: cover;    
  @media (max-width: 1200px) {
  background-image: url(${aboutBg1200});
  }
`

export const AboutCompetitionBackground = styled.div`
  width: 100%;
  height: 460px;
  padding-bottom: 20px;
  background-size: cover;
  background-image: url(${aboutBg1900}); 
  
`

export const DescriptionContainer = styled.div`
  display: flex;
  padding-top: 80px;
  & p {
    max-width: 715px; 
    color: #ffffff;
    font-family: Roboto, sans-serif;    
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;

  } 
  & h1 {
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-transform: uppercase;
  padding-left: 71px;
  letter-spacing: 1.2px;
  }
  
`

export const DescriptionIcon = styled.div`
  margin: 0 34px;
  width: 87px;
  height: 70px;
  background-image: url(${aboutIcon});
  
`
