import {combineReducers} from "redux";
import {authReducer} from "./reducers/authReducer";
import {competitorReducer} from "./reducers/competitorReducer";
import {moderatorReducer} from "./reducers/moderatorReducer";
import {expertReducer} from "./reducers/expertReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  competitor: competitorReducer,
  moderator: moderatorReducer,
  expert: expertReducer
})