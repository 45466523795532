import {
  CHANGE_INFO_VALUE,
  CHANGE_STEP,
  CHANGE_UPLOAD_FILE,
  DESABLED_UPLOAD_FILE, DID_UPLOAD_WORK,
  IS_DESABLE_BUTTON, SET_CERT, UPLOAD_FETCH_DATA
} from "../Types/competitorsTypes";

const initialState = {
  step: 'profile',
  isDisabled: true,
  cert: false,
  infoCard: {
    region: '',
    location: '',
    name: '',
    surname: '',
    patronymic: '',
    workplace: '',
    position: '',
    experience: '',
    mail: '',
    email: '',
    phone: '',
    thematic: '',
    theme: '',
    classes: '',
    annotation: '',
    accept: false,
    agreePdf: '',
    infoCardPdf: ''
  },
  uploadWorkFiles: {
    pdfWork: {
      title : 'Файл не выбран...',
      upload: false
    },
    pdfWorkNoTitle: {
      title : 'Файл не выбран...',
      upload: false
    },
    docWork: {
      title : 'Файл не выбран...',
      upload: false
    },
    otherFiles: {
      title : 'Файл не выбран...',
      upload: true
    },
  },
  isDisabledFilesUpload: true,
  didUploadWork: true,
}

export const competitorReducer = (state = initialState, action) => {
  let isDisabled = false
  switch (action.type) {
    case CHANGE_STEP: return {...state, step: action.payload}
    case CHANGE_INFO_VALUE: return  {...state, infoCard: {...state.infoCard, [action.id]: action.payload}}
    case CHANGE_UPLOAD_FILE: return {...state, uploadWorkFiles: {...state.uploadWorkFiles, [action.id]: { title: action.payload, upload: true}}}
    case UPLOAD_FETCH_DATA: return {...state, infoCard: action.payload}
    case DID_UPLOAD_WORK: return {...state, didUploadWork: action.payload}
    case DESABLED_UPLOAD_FILE:
      for (const key in state.uploadWorkFiles) {
        if (state.uploadWorkFiles[key].upload === false) {
          isDisabled = true
        }
      }
      return {...state, isDisabledFilesUpload: isDisabled}
    case IS_DESABLE_BUTTON:

      const dataValues = Object.values(state.infoCard)
      dataValues.forEach(node => {
        if (node === '' || node === false) {
          isDisabled = true
        }
      })
      return {...state, isDisabled: isDisabled}
    case SET_CERT: return {...state, cert: action.payload}
    default: return state
  }
}