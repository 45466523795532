import React, {useEffect} from "react";
import {
  BreadcrumbsItem,
  BreadcrumbsItemLink, DocsContainer,
  DocumentsPageContainer,
  DocumentsPageContent
} from "../Style/DocumentsPage/DocumentsPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumbs, Breadcrumb} from "react-breadcrumbs";
import {PageTitle} from "../Style/Pages";
import {DocsTypes} from "../containers/DocumentsPage/DocsTypes";

export const DocumentsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <DocumentsPageContainer>
      <DocumentsPageContent>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={' / '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>Документы</BreadcrumbsItemLink>,
                  pathname: '/docs'
                }}/>
              </Breadcrumbs>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <PageTitle>Документы</PageTitle>
            </Col>
          </Row>

          <Row>
            <DocsContainer>
              <DocsTypes/>
            </DocsContainer>
          </Row>

        </Grid>
      </DocumentsPageContent>
    </DocumentsPageContainer>
  )
}