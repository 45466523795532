import {Redirect, Route, Switch} from "react-router-dom";
import {HomePage} from "../view/HomePage";
import {MainLayout} from "../layout/MainLayout";
import {DocumentsPage} from "../view/DocumentsPage";
import {CalendarPage} from "../view/ClendarPage";
import {NewsPage} from "../view/NewsPage";
import {CompetitorPage} from "../view/CompetitorPage";
import {ExpertPage} from "../view/ExpertPage";
import {AboutPage} from "../view/AboutPage";
import {TopicPage} from "../view/TopicPage";
import React from "react";
import {AuthPage} from "../view/LoginPage";
import {RegisterUser} from "../view/RegisterUser";
import {RedirectStatic} from "../components/RedirectStatic";
import {AdminPanelConnector} from "../view/AdminPanelConnector";
import {ResultPage} from "../view/ResultPage";
import {CertPage} from "../view/CertPage";
import {RegionalPage} from "../view/RegionalPage";

export const useRoutes = isAuthenticated => {

  if (isAuthenticated) {
    return (
      <Switch>
        <Route path='/' exact>
          <HomePage/>
        </Route>
        <Route path='/docs'>
          <MainLayout>
            <DocumentsPage/>
          </MainLayout>
        </Route>
        <Route path='/calendar'>
          <MainLayout>
            <CalendarPage/>
          </MainLayout>
        </Route>
        <Route path='/news'>
          <MainLayout>
            <NewsPage/>
          </MainLayout>
        </Route>
        <Route path='/results'>
          <MainLayout>
            <ResultPage/>
          </MainLayout>
        </Route>
        <Route path='/cert'>
          <CertPage/>
        </Route>
        <Route path='/competitor'>
          <MainLayout>
            <CompetitorPage/>
          </MainLayout>
        </Route>
        <Route path='/expert'>
          <MainLayout>
            <ExpertPage/>
          </MainLayout>
        </Route>
        <Route path='/about'>
          <MainLayout>
            <AboutPage/>
          </MainLayout>
        </Route>
        <Route path='/topic'>
          <MainLayout>
            <TopicPage/>
          </MainLayout>
        </Route>
        <Route path='/login'>
          <AuthPage/>
        </Route>
        <Route path='/admin' component={AdminPanelConnector}>
        </Route>
        <Route path='/registerNewUserSecret'>
          <RegisterUser/>
        </Route>
        <Route path='/static'>
          <RedirectStatic/>
        </Route>
        <Route path='/regional'>
          <MainLayout>
            <RegionalPage/>
          </MainLayout>
        </Route>
        <Redirect to="/"/>
      </Switch>
    )
  }

  return(
    <Switch>
      <Route path='/' exact>
        <HomePage/>
      </Route>
      <Route path='/cert'>
        <CertPage/>
      </Route>
      <Route path='/results'>
        <MainLayout>
          <ResultPage/>
        </MainLayout>
      </Route>
      <Route path='/docs'>
        <MainLayout>
          <DocumentsPage/>
        </MainLayout>
      </Route>
      <Route path='/calendar'>
        <MainLayout>
          <CalendarPage/>
        </MainLayout>
      </Route>
      <Route path='/news'>
        <MainLayout>
          <NewsPage/>
        </MainLayout>
      </Route>
      <Route path='/competitor'>
        <MainLayout>
          <CompetitorPage/>
        </MainLayout>
      </Route>
      <Route path='/expert'>
        <MainLayout>
          <ExpertPage/>
        </MainLayout>
      </Route>
      <Route path='/regional'>
        <MainLayout>
          <RegionalPage/>
        </MainLayout>
      </Route>
      <Route path='/about'>
        <MainLayout>
          <AboutPage/>
        </MainLayout>
      </Route>
      <Route path='/topic'>
        <MainLayout>
          <TopicPage/>
        </MainLayout>
      </Route>
      <Route path='/login'>
        <AuthPage/>
      </Route>
      <Redirect to="/"/>
    </Switch>
  )
}