import React, {useEffect} from "react";
import {AdminPanelViewTitle, ModeratorContainer, ModeratorContent} from "../../Style/Admin/AdminPanel";
import {usePagination, useTable} from "react-table";
import {Pagination, PaginationButton, PaginationButtonCurrent, TableStyle} from "../../Style/TableStyles";
import {useDispatch, useSelector} from "react-redux";
import Loader from "react-loader-spinner";
import {useHttp} from "../../hooks/http.hooks";
import {setLoading} from "../../Redux/actions/authActions";
import {setRatingData} from "../../Redux/actions/moderatorActions";

export const AdminModeratorRating = () => {
  const {data, columns} = useSelector(state => state.moderator.ratingOption)
  const {loading} = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const {request} = useHttp()

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true))
        const data = await request('/api/moderator/rating')
        console.log(data)
        dispatch(setRatingData(data))
        dispatch(setLoading(false))
      } catch (e) {
        console.log(e)
        dispatch(setLoading(false))
      }
    }

    fetchData()
  }, [request, dispatch])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex},
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10}
    },
    usePagination
  )
  return (
    <ModeratorContainer>
      <ModeratorContent>
        <AdminPanelViewTitle>Рейтинг участников Конкурса</AdminPanelViewTitle>
        <TableStyle>
          <table {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
            </tbody>
          </table>
          {!loading || <Pagination><Loader type="ThreeDots" color="#f6a117" height={40} width={40} /></Pagination>}
          {loading ||
          <Pagination>
            <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </PaginationButton>
            <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
              {pageIndex}
            </PaginationButton>
            <PaginationButtonCurrent>
              {pageIndex + 1}
            </PaginationButtonCurrent>
            <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
              {pageIndex + 2}
            </PaginationButton>
            <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </PaginationButton>
          </Pagination>
          }
        </TableStyle>
      </ModeratorContent>
    </ModeratorContainer>
  )
}