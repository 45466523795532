import {
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_REMEMBER,
  SET_AUTH, SET_LOADING,
  SET_ROLE,
  SET_TOKEN,
  SET_USERID
} from "../Types/authTypes";
import {DID_UPLOAD_WORK} from "../Types/competitorsTypes";


export const changeLogin = value => {
  return {
    type: CHANGE_LOGIN,
    payload: value
  }
}

export const setLoading = value => {
  return {
    type: SET_LOADING,
    payload: value
  }
}

export const changePassword = value => {
  return {
    type: CHANGE_PASSWORD,
    payload: value
  }
}

export const authorization = (value = false) => {
  return {
    type: SET_AUTH,
    payload: value
  }
}

export const changeRemember = (value = false) => {
  return {
    type: CHANGE_REMEMBER,
    payload: value
  }
}

export const setToken = value => {
  return {
    type: SET_TOKEN,
    payload: value
  }
}

export const setUserId = id => {
  return {
    type: SET_USERID,
    payload: id
  }
}

export const didUploadWork = bool => {
  return {
    type: DID_UPLOAD_WORK,
    payload: bool
  }
}

export const setRole = value => {
  return {
    type: SET_ROLE,
    payload: value
  }
}