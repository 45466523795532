import styled from 'styled-components'

export const CompetitorsViewTitle = styled.h1`
  color: #052b46;
  font-family: "Roboto Condensed",sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
`
export const CompetitorsViewDescription = styled.h2`
  color: #052b46;
  font-family: Roboto,sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
`

export const InfoCardItem = styled.div`
  max-width: 600px;
  margin: 15px 0 20px;
  
  & p {
    font-size: 16px;
    line-height: 20px;
    color: #616875;
    font-family: "Roboto",sans-serif;
    font-weight: 400;
  }
`

export const InfoCardDiscription = styled.div`
  font-size: 18px;
  line-height: 20px;
  color: #616875;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  padding-bottom: 10px;
  
  & p {
    font-size: 16px;
  }

`

export const Width500px = styled.div`
  max-width: 500px;
`