import React from "react";
import {NewsContainer, NewsContent} from "../../Style/HomePage/News";
import {NewsCompetitions} from "../../components/News/NewsCompetitions";
import {NewsCalendar} from "../../components/News/NewsCalendar";

export const News = () => {

  return(
    <NewsContainer>
      <NewsContent>
        <NewsCompetitions/>
        <NewsCalendar/>
      </NewsContent>
    </NewsContainer>
  )
}
