import React from "react";
import {AdminPanelViewTitle, ModeratorContainer, ModeratorContent, UploadButton} from "../../Style/Admin/AdminPanel";
import {InfoCardDiscription, InfoCardItem, Width500px} from "../../Style/Admin/CompetitorsView";
import Select from "react-select";
import {regionOptions} from "../../Docs/select.options";
import {InputAdmin} from "../../Style/UI";
import {useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";

export const AdminModeratorRegisterExpert = () => {
  const {token} = useSelector(state => state.auth)

  const registerHandler = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    try {
      const response = await fetch('/api/moderator/registerExpert', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData
      })
      const {message} = await response.json()
      toast.info(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    } catch (e) {
      toast.error("Произошла ошибка", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }

  }

  return (
    <ModeratorContainer>
      <ToastContainer/>
      <ModeratorContent>
        <AdminPanelViewTitle>Регистрация Эксперта</AdminPanelViewTitle>
        <form onSubmit={e => registerHandler(e)} encType="multipart/form-data">
        <InfoCardItem>
          <InfoCardDiscription>Субъект Российской Федерации</InfoCardDiscription>
          <Width500px>
            <Select
              options={regionOptions}
              placeholder={'Регион эксперта...'}
              name={'region'}
              required
            />
          </Width500px>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>ФИО Эксперта</InfoCardDiscription>
          <Width500px>
            <InputAdmin
              type={'text'}
              placeholder={'ФИО...'}
              name={'name'}
              required
            />
          </Width500px>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Логин</InfoCardDiscription>
          <Width500px>
            <InputAdmin
              type={'text'}
              placeholder={'Логин...'}
              name={'login'}
              required
            />
          </Width500px>
        </InfoCardItem>
        <InfoCardItem>
          <InfoCardDiscription>Пароль</InfoCardDiscription>
          <Width500px>
            <InputAdmin
              type={'password'}
              placeholder={'Пароль...'}
              name={'password'}
              required
            />
          </Width500px>
        </InfoCardItem>
        <UploadButton
          type={'submit'}
        >Регистрация</UploadButton>
        </form>
      </ModeratorContent>
    </ModeratorContainer>
  )
}