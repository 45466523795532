import {
  CLEAR_EXPERT_ID_DATA,
  CLEAR_USER_ID_DATA, SET_EXPERTS_LIST, SET_EXPORT_USERS_DATA, SET_RATING_DATA,
  UPLOAD_EXPERT_DATA, UPLOAD_EXPERT_ID,
  UPLOAD_USER_DATA,
  UPLOAD_USER_DATA_BY_ID
} from "../Types/moderatorTypes";
import React from "react";
import {NavLink} from "react-router-dom";
import {FileLink, UserModeratorLink, UserModeratorList} from "../../Style/Admin/AdminPanel";
import {server} from "../../config";

export const uploadModeratorUserData = data => {
  const newData = []

  const setExpertName = node => {
    return (
      <div key={node.id}>
        <NavLink to={`/admin/experts/${node.id}`}>
          <UserModeratorList>{node.name}</UserModeratorList>
        </NavLink>
        <br/>
      </div>
    )
  }

  data.forEach(node => {
    let score = 0
    const setNameLink = () => (
      <NavLink to={`/admin/users/${node.id}`}>
        <UserModeratorList>{node.memberName}</UserModeratorList>
      </NavLink>
    )
    node.experts.forEach(node => {
      score += +node.score
    })

    newData.push({
      region: node.region,
      thematic: node.thematic,
      theme: node.theme,
      workFiles: node.workFiles !== null ? node.workFiles.map((item, i) => (
        <div key={node.id + i}>
          <UserModeratorLink href={`${server}` + item.url} target='_blank' rel="noopener noreferrer">{item.title}</UserModeratorLink>
          <br/>
          <br/>
        </div>
      )) : '',
      memberName: setNameLink(),
      score: (score/node.experts.length) ? (score/node.experts.length).toFixed(4) :  '',
      experts: node.experts.map(node => setExpertName(node))
    })
  })
  return {
    type: UPLOAD_USER_DATA,
    payload: newData
  }
}

export const saveUserDataById = data => {
  const setAgreeFile = () => (
    <FileLink href={`${server}` + data.agreePdf.url} target='_blank' rel="noopener noreferrer">{data.agreePdf.title}</FileLink>
  )
  const newData ={...data, agreePdf: setAgreeFile()}
  return {
    type: UPLOAD_USER_DATA_BY_ID,
    payload: newData
  }
}

export const clearUserData = () => {
  return {
    type: CLEAR_USER_ID_DATA
  }
}

export const uploadExpertsData = data => {
  const newData = []
  const setExpertName = node => (
    <NavLink to={`/admin/experts/${node.id}`}>
      <UserModeratorList>{node.name}</UserModeratorList>
    </NavLink>
  )

  const setUsersName = node => (
    <div key={node.id}>
      <NavLink to={`/admin/users/${node.id}`}>
        <UserModeratorList style={{minHeight: '84px'}}>{node.name}</UserModeratorList>
      </NavLink>
    </div>
  )
  const setUserStatus = node => {
    if (node.checked) {
      return (
        <div style={{minHeight: '84px'}} key={node.id}>
          <p>Проверено</p>
        </div>
      )
    } else {
      return (
        <div style={{minHeight: '84px'}} key={node.id}>
          <p>Не проверено</p>
        </div>
      )
    }
  }

  for (const node of data) {
    newData.push({
      memberName: setExpertName(node),
      region: node.region,
      workPlace: node.workplace,
      users: node.users.map(node => setUsersName(node)),
      status: node.users.map(node => setUserStatus(node))
    })
  }

  return {
    type: UPLOAD_EXPERT_DATA,
    payload: newData
  }
}

export const uploadExpertById = data => {
  return {
    type: UPLOAD_EXPERT_ID,
    payload: data
  }
}
export const clearExpertData = () => {

  return {
    type: CLEAR_EXPERT_ID_DATA
  }
}

export const setExportUserData = data => {
  return {
    type: SET_EXPORT_USERS_DATA,
    payload: data
  }
}

export const setExpertsList = data => {
  const newData = []
  data.forEach(node => {
    newData.push({
      value: node.id,
      label: node.name + ' - ' + node.region
    })
  })
  return {
    type: SET_EXPERTS_LIST,
    payload: newData
  }
}

export const setRatingData = data => {
  const setUserNameLink = item => (
    <div key={item.id}>
      <NavLink to={`/admin/users/${item.id}`}>
        <UserModeratorList style={{minHeight: '84px'}}>{item.memberName}</UserModeratorList>
      </NavLink>
    </div>
  )

  const setExpertsName = items => {
    return (
      items.map(item => (
        <div key={item.id}>
          <NavLink to={`/admin/experts/${item.id}`}>
            <UserModeratorList>{item.expertName}</UserModeratorList>
          </NavLink>
          <br/>
        </div>
      ))
    )
  }

  const setExpertScore = items => {
    return (
      items.map(item => (
        <div style={{minHeight: '65px'}} key={item.id}>
          <p>{item.score}</p>
        </div>
      ))
    )
  }
  const newData = []
  data.forEach(node => {
    newData.push({
      memberName: setUserNameLink(node),
      region: node.region,
      thematic: node.thematic,
      experts: setExpertsName(node.experts),
      expertScore: setExpertScore(node.experts),
      score: node.score.toFixed(4)
    })
  })
  // newData.sort((a, b) => a.score > b.score ? 1 : -1).reverse()
  return {
    type: SET_RATING_DATA,
    payload: newData
  }
}