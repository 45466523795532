import React from "react";
import {
  AdminPanelSidebarContainer,
  AdminPanesSidebarTitle, SidebarList, SidebarMenu,
  UserImage,
  UserInfo, UserStatus
} from "../../Style/Admin/AdminPanel";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authorization, setToken} from "../../Redux/actions/authActions";

export const AdminExpertSidebar = () => {
  const dispatch = useDispatch()


  const onExitHandler = () => {

    localStorage.removeItem('userData')
    dispatch(setToken(''))
    dispatch(authorization())
  }

  return(
    <AdminPanelSidebarContainer>
      <UserInfo>
        <UserImage><i className="far fa-user"/></UserImage>
      </UserInfo>
      <UserStatus>Ваш статус:</UserStatus>
      <AdminPanesSidebarTitle>Эксперт</AdminPanesSidebarTitle>

      <SidebarMenu>
        <NavLink to={'/admin/info'}><SidebarList>Информационная Карта</SidebarList></NavLink>
        <NavLink to={'/admin/check'}><SidebarList>Проверка работы</SidebarList></NavLink>
        <NavLink to={'/'}>
          <SidebarList>Главная</SidebarList>
        </NavLink>
        <SidebarList onClick={() => onExitHandler()}>Выход</SidebarList>

      </SidebarMenu>

    </AdminPanelSidebarContainer>
  )
}