import React from 'react';
import {
  ResultHomeBox,
  ResultHomeContainer,
  ResultHomeContent,
  ResultHomeText,
} from '../../Style/HomePage/News';

export const ResultHome = () => {
  return (
    <ResultHomeContainer>
      <ResultHomeContent>
        <ResultHomeBox>
          <ResultHomeText>
            <p>
              &#8195;&#8195;<b>Подведены итоги IV Всероссийского дистанционного конкурса на лучшие методические разработки воспитательных мероприятий.</b>{' '}
            </p>
            <p>
              &#8195;&#8195;Конкурс проходил в 2 этапа: региональный этап – с 27 марта по 22 мая, федеральный – с 23 мая по 14 августа 2023 года.
              </p>
            <p>
              &#8195;&#8195;От каждого субъекта РФ на федеральный этап Конкурса направлено от трех до пяти индивидуальных методических разработок участников – победителей регионального этапа.
            </p>
            <p>
              &#8195;&#8195;<b>На федеральный этап Конкурса поступило 372 работы из 88 субъектов Российской Федерации.</b> {' '}
            </p>
            <p>
              &#8195;&#8195; После технической экспертизы и проверки на антиплагиат к оцениванию допущено 332 работы, 40 работ отклонено за низкий уровень оригинальности текста, несоблюдение формата представления.
            </p>
            <p>
              &#8195;&#8195; Состав жюри сформирован из кандидатов, делегированных органами исполнительной власти субъектов Российской Федерации, осуществляющими государственное управление в сфере образования, и утвержден Департаментом подготовки, профессионального развития и социального обеспечения педагогических работников Министерства просвещения Российской Федерации.
            </p>
            <p>
              &#8195;&#8195;Итоговая оценка одного конкурсного материала представляет собой среднее арифметическое баллов, выставленных тремя членами жюри. Максимальное количество баллов – 60. От 50 до 58 баллов за разработку набрали 32 участника (10%), от 45 до 49 баллов – 55 участников (16%), от 31 до 44 баллов – 205 конкурсантов (62%).
            </p>
            <p>
              &#8195;&#8195;Победитель, призеры и лауреаты Конкурса определены на основании рейтингового списка. Согласно Положению о Конкурсе победителем и призерами IV Всероссийского дистанционного конкурса среди классных руководителей на лучшие методические разработки воспитательных мероприятий объявлены конкурсанты, набравшие более 55 баллов, лауреатами – участники, занимающие позиции рейтингового списка, следующие за группой призеров, набравшие более 45 баллов. Список размещен в разделе Документы.
            </p>
            <p>
              &#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;<b>Поздравляем победителя, призеров и лауреатов Конкурса!</b>{' '}
            </p>
          </ResultHomeText>
        </ResultHomeBox>
      </ResultHomeContent>
    </ResultHomeContainer>
  );
};
