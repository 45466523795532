import React, {useEffect} from "react";
import {AdminPanelComponent, AdminPanelContainer, AdminPanelContent} from "../../Style/Admin/AdminPanel";
import {AdminHeader} from "../../components/AdminPanel/AdminHeader";
import {AdminCompetitorSidebar} from "../../components/AdminPanel/AdminCompetitorSidebar";
import {CompetitorView} from "../../components/AdminPanel/CompetitorView";
import {useHttp} from "../../hooks/http.hooks";
import {useDispatch, useSelector} from "react-redux";
import {isAllValueSet, setCert, uploadFetchedData} from "../../Redux/actions/competitiorActions";
import {authorization, setToken} from "../../Redux/actions/authActions";

export const AdminCompetitor = () => {
  const token = useSelector(state => state.auth.token)
  const {request} = useHttp()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await request('/api/user/current_user_data', 'GET', null , {
          Authorization: `Bearer ${token}`
        })
        if (data === null) {
          dispatch(authorization(false))
          dispatch(setToken(''))
        }
        const result = {}
        dispatch(setCert(data.cert))
        for (let key in data) {
          if (key !== 'owner' && key !== '_id' && key !== '__v' && key !== 'cert') {
            result[key] = data[key]
          }
        }

        dispatch(uploadFetchedData(result))
        dispatch(isAllValueSet())
      } catch (e) {
        console.log(e)
        dispatch(authorization(false))
        dispatch(setToken(''))
      }
    }

    fetchUserData()

  }, [request, token, dispatch])

  return(
    <AdminPanelContainer>
      <AdminHeader/>
      <AdminPanelContent>
        <AdminCompetitorSidebar/>
        <AdminPanelComponent>
          <CompetitorView/>
        </AdminPanelComponent>
      </AdminPanelContent>
    </AdminPanelContainer>
  )
}